import React, { useEffect, useMemo, useState } from 'react';
import {
  TimesheetActionContainer,
  TimesheetContainer,
} from './timesheet.style';
import { AppIcon } from '../../icons';
import { Button, FieldText, Textarea } from '../../components';
import { AxiosInstance, formatDate, getTotalWorkingHours } from '../../utils';
import { toast } from 'react-toastify';
import { AddTimingsDto, Timesheet } from '../../backend/careo-api';
import { useLocation, useParams } from 'react-router-dom';
import { durations } from '../../constants';

type TimesheetClientPageProps = {
  isDownload?: boolean;
};

const TimesheetClientPage = ({ isDownload }: TimesheetClientPageProps) => {
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const download = queryParams.get('download');

  const [timings, setTimings] = useState<AddTimingsDto[]>([]);
  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [signature, setSignature] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const totalOfWorkingHours: string = useMemo(() => {
    return getTotalWorkingHours(timings);
  }, [timings]);

  const getTimesheetDetails = async () => {
    try {
      let result;
      if (isDownload) {
        result = (await AxiosInstance.timesheets.timesheetsControllerFindOne(
          token!,
        )) as unknown as Timesheet;
      } else {
        result =
          (await AxiosInstance.timesheetMember.timesheetMemberControllerFindOneByToken(
            token!,
          )) as unknown as Timesheet;
      }

      if (result) {
        setTimesheet(result);
        setTimings((result.timings ?? []) as AddTimingsDto[]);
        if (result.status === 'Approved') {
          setSignature(result.signature ?? '');
          setIsAgreed(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickApprove = async () => {
    try {
      await AxiosInstance.timesheetMember.timesheetMemberControllerApproveByClientToken(
        token!,
        {
          signature,
        },
      );
      toast.success('Timesheet approved successfully');
      getTimesheetDetails();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickReject = async () => {
    try {
      await AxiosInstance.timesheetMember.timesheetMemberControllerRejectByClientToken(
        token!,
      );
      getTimesheetDetails();
      toast.success('Timesheet rejected successfully');
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    getTimesheetDetails();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (!timesheet || timesheet?.status === 'Generated') {
    return <>Timesheet not found</>;
  }

  return (
    <>
      <TimesheetContainer className="container" id="container">
        <div className="logo-container">
          <AppIcon />
        </div>
        <div className="title">
          {timesheet?.status === 'Pending'
            ? 'Please approve the submitted timesheet below'
            : timesheet?.status === 'Approved'
            ? 'Timesheet is already approved'
            : 'Timesheet is already rejected'}
        </div>
        <div className="title">Timesheet</div>
        <FieldText
          label="Placement"
          value={
            timesheet?.placement.job.client.clientName +
            ' - ' +
            timesheet?.placement.job.specialty
          }
          disabled
        />
        <div className="subtitle">Personal Details</div>
        <div className="personal-info-container">
          <div className="personal-name">
            {timesheet?.placement?.candidate?.title}{' '}
            {timesheet?.placement?.candidate?.firstName}{' '}
            {timesheet?.placement?.candidate?.lastName}
          </div>
          <div className="row">
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Specialty</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Department</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate?.department || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Week Commencing</div>
              <div className="info-item-data col-7">
                {formatDate(timesheet?.placement?.availableFrom ?? '')}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Team</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job.specialty}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Email Address</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Grade</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job?.grade}
              </div>
            </div>
          </div>
        </div>
        <div className="subtitle">Timesheet below</div>
        <div className="text">
          Breaks in the same detail as the Assignment confirmation
        </div>
        {timings?.map((el, index) => {
          return (
            <div className="personal-info-container">
              <div className="personal-name">
                {new Date(el.date).toLocaleDateString('en-US', {
                  weekday: 'long',
                })}{' '}
                - {formatDate(el.date)}
              </div>
              <div className="row">
                <div className="col-md-6 row">
                  <div className="info-item-title col-6"> Start Time</div>
                  <div className="info-item-data col-6">{el.startTime} </div>
                  <div className="info-item-title col-6"> Finish Time</div>
                  <div className="info-item-data col-6">{el.endTime} </div>
                </div>
                <div className="col-md-6 row">
                  <div className="row">
                    <div className="info-item-title col-6">On Call Hours</div>
                    <div className="info-item-data col-6">
                      {durations.find(
                        (duration) => duration.value === el.callHours,
                      )?.label ?? 0}{' '}
                    </div>
                    <div className="info-item-title col-6"> Breaks</div>
                    <div className="info-item-data col-6">
                      {durations.find(
                        (duration) => duration.value === el.breakTime,
                      )?.label ?? 0}{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="subtitle">Verified by authorized signature holder</div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="info-item-title col-5">Name</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job?.client.firstName}{' '}
                {timesheet?.placement?.job?.client?.lastName}
              </div>
            </div>
            <div className="row">
              <div className="info-item-title col-5">Position</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job?.client?.jobTitle}
              </div>
            </div>
          </div>
          <div className="col-md-6 signature-container">
            <div className="info-item-title">Signature</div>
            <Textarea
              className="textarea"
              onChange={(e) => setSignature(e.target.value)}
              disabled={timesheet?.status !== 'Pending'}
              value={signature}
            />
          </div>
        </div>
        <div className="agree-container">
          <input
            type="checkbox"
            id="agree-checkbox"
            onChange={(e) => setIsAgreed(e.target.checked)}
            disabled={timesheet?.status !== 'Pending'}
            checked={isAgreed}
          />
          <label className="checkbox-text" htmlFor="agree-checkbox">
            I agree all the information submitted is correct
          </label>
        </div>
        <div className="declaration-container">
          <div className="text title-declaration">Declaration:</div>
          <div className="text">
            I declare that the information above is current and complete, and
            all hours are approved by an authorized supervisor and signature
            holder. Providing false information can result in the event of
            investigation so please alert us immediately if you notice an error
            in how many hours are claimed. Signed timesheets will follow the
            terms and conditions linked to this locum assignment. For access to
            the agreement please contact your account manager.
          </div>
          <br />
          <div className="text contact-declaration">
            For information relating to payment or any other matter relating to
            this assignment, please contact us.
          </div>
        </div>
      </TimesheetContainer>
      {download !== 'pdf' && (
        <TimesheetActionContainer className="container">
          <div className="left-container">
            Total Working Time : <label>{totalOfWorkingHours}</label>
          </div>
          <div className="right-container">
            <Button
              type="default"
              onClick={onClickReject}
              disabled={!isAgreed || timesheet?.status !== 'Pending'}
            >
              Reject
            </Button>
            <Button
              type="primary"
              disabled={!isAgreed || timesheet?.status !== 'Pending'}
              onClick={onClickApprove}
            >
              Approve
            </Button>
          </div>
        </TimesheetActionContainer>
      )}
    </>
  );
};

export default TimesheetClientPage;
