import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const SelectContainer = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    span {
      color: red;
    }
  }

  .error-container {
    font-size: 13px;
    color: red;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e9eaec;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-color: ${(props) => (props.hasError ? 'red' : '')};
  }

  .mui-input .Mui-disabled .MuiOutlinedInput-notchedOutline,
  .mui-input .MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e9eaec !important;
  }

  .mui-input fieldset.MuiOutlinedInput-notchedOutline:focus-visible,
  .mui-input .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  .Mui-expanded
    .mui-input
    .Mui-focused
    fieldset.MuiOutlinedInput-notchedOutline {
    border: 2px solid #c2c3c6 !important;
    outline: unset !important;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: 100%;
    label {
      font-size: 10px;
    }
    .error-container {
      font-size: 9px;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      font-size: 10px;
    }
    .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    input {
      font-size: 10px;
    }

    span.MuiChip-label.MuiChip-labelMedium {
      font-size: 8px;
    }

    ul.MuiAutocomplete-listbox li.MuiAutocomplete-option {
      font-size: 10px;
      min-height: 30px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      height: 40px;
    }
  }
`;
