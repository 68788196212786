export const GBPFormatter = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatCurrency = (value: number | undefined) => {
  if (!value) {
    return '-';
  }

  return GBPFormatter.format(value);
};
