import styled from 'styled-components';

export const OfferContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @supports (height: 100dvh) {
    min-height: 100dvh;
  }
  .content {
    display: flex;
    padding: 36px;
    padding: 99px;
    flex-direction: column;
    gap: 24px;
    background-color: #fafafa;

    color: #111827;
    .title {
      font-size: 24px;
      font-weight: 500;
    }
    .subtitle {
      font-size: 18px;
      font-weight: 700;
    }
    .accordion {
      .accordion-button {
        background-color: #f7f7f7 !important;
      }
    }
    .personal-info-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 24px;
      border-radius: 16px;
      border: 1px solid #f1f2f4;
    }

    .personal-name {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    .info-item-title {
      color: #687588;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    .info-item-data {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    .signature-container {
      display: flex;
      align-items: center;
      gap: 10px;
      .textarea {
        flex: 1;
      }
    }

    .text {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: #687588;
    }

    .agree-container {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .declaration-container {
      .title-declaration {
        color: #000;
      }
      .contact-declaration {
        color: #000;
        font-weight: 700;
        line-height: 160%;
      }
    }
  }

  .action-container {
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-container {
      label {
        color: #27a376;
      }
    }
    .right-container {
      display: flex;
      gap: 20px;
    }
  }
`;
