import React, { ChangeEvent, useEffect, useRef } from 'react';
import { TextareaContainer } from './textarea.style';
import { FieldError } from 'react-hook-form';

type TextareaProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  register?: any;
  className?: string;
  min?: number;
  max?: number;
  rows?: number;
  disabled?: boolean;
  onKeyDown?: any;
  error?: FieldError;
};

export const Textarea = ({
  label,
  placeholder = '',
  required,
  onChange,
  value,
  register,
  className,
  max,
  min,
  disabled,
  rows = 3,
  error,
  ...props
}: TextareaProps) => {
  const textAreaRef = useRef<any>(null);
  const maxHeight = 85;

  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef.current.style.height = 'auto';
      const scrollHeight =
        textAreaRef.current.scrollHeight > maxHeight
          ? maxHeight
          : textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);

  return (
    <TextareaContainer className={className} hasError={!!error}>
      {label && (
        <label htmlFor="">
          {label} {required && <span>*</span>}
        </label>
      )}
      <div className="input-container">
        <textarea
          {...props}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          min={min}
          max={max}
          {...register}
          disabled={disabled}
          ref={textAreaRef}
          rows={rows}
          {...register}
          class="form-control"
        />
        {error && <span className="error-container">{error?.message}</span>}
      </div>
    </TextareaContainer>
  );
};
