import React, { useEffect, useState } from 'react';
import { OfferContainer } from './offer.style';
import { AppIcon } from '../../icons';
import { Button, FieldText } from '../../components';
import { AxiosInstance, formatDate, formatCurrency } from '../../utils';
import {
  Application,
  EApplicationApprovalStatus,
} from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const messagesRecord: Record<EApplicationApprovalStatus, string> = {
  [EApplicationApprovalStatus.NOT_ACTIVE]: '',
  [EApplicationApprovalStatus.PENDING]: '',
  [EApplicationApprovalStatus.CLIENT_CONFIRMED]:
    'Please confirm your Agency Locum placement',
  [EApplicationApprovalStatus.CLIENT_REJECTED]: '',
  [EApplicationApprovalStatus.CANDIDATE_CONFIRMED]:
    'You have confirmed the placement.',
  [EApplicationApprovalStatus.CANDIDATE_REJECTED]:
    'You have rejected the placement.',
  [EApplicationApprovalStatus.RECRUITER_APPROVED]:
    'Congrats! The recruiter has approved the placement.',
  [EApplicationApprovalStatus.RECRUITER_DECLINED]:
    'Unfortunately, the recruiter has declined the placement.',
  [EApplicationApprovalStatus.RECRUITER_CANCELED]:
    'Unfortunately, the recruiter has cancelled the placement.',
  [EApplicationApprovalStatus.RECRUITER_STOPPED]:
    'Unfortunately, the recruiter has cancelled the placement.',
  [EApplicationApprovalStatus.SYSTEM_CANCELLED]:
    'Unfortunately, the placement has been cancelled automatically.',
};

const OfferCandidatePage = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [placement, setPlacement] = useState<Application | null>(null);

  const getOfferDetails = async () => {
    try {
      const result =
        (await AxiosInstance.placementMember.placementMemberControllerFindOneByCandidateToken(
          token!,
        )) as unknown as Application;
      setPlacement(result);
    } catch (error) {
      toast.error('Something went wrong');
    }

    setIsLoading(false);
  };

  const onClickApprove = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerCandidateConfirmByToken(
        token!,
      );
      toast.success('Offer sent successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  const onClickReject = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerCandidateRejectByToken(
        token!,
      );
      toast.success('Offer rejected successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  useEffect(() => {
    getOfferDetails();
  }, []);

  if (isLoading) {
    return <>loading ...</>;
  }

  if (!placement) {
    return <>Offer not found</>;
  }

  return (
    <OfferContainer>
      <div className="content container" id="container">
        <div className="logo-container">
          <AppIcon />
        </div>

        <div className="title">
          {messagesRecord[placement.approvalStatus!] ?? ''}
        </div>

        <FieldText
          label="Placement"
          value={
            placement?.job.client.clientName + ' - ' + placement?.job.specialty
          }
          disabled
        />
        <div className="subtitle">Placement Details</div>
        <div className="personal-info-container">
          <div className="personal-name">
            {placement?.candidate?.title} {placement?.candidate?.firstName}{' '}
            {placement?.candidate?.lastName}
          </div>
          <div className="row">
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Specialty</div>
              <div className="info-item-data col-7">
                {placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Department</div>
              <div className="info-item-data col-7">
                {placement?.candidate?.department || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Week Commencing</div>
              <div className="info-item-data col-7">
                {formatDate(placement?.availableFrom ?? '')}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Team</div>
              <div className="info-item-data col-7">
                {placement?.job.specialty || '-'}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Email Address</div>
              <div className="info-item-data col-7">
                {placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Grade</div>
              <div className="info-item-data col-7">
                {placement?.job?.grade}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Start Date</div>
              <div className="info-item-data col-7">
                {formatDate(placement.availableFrom)}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">End Date</div>
              <div className="info-item-data col-7">
                {formatDate(placement.availableTo)}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Pay Amount</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.job.payAmount)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">On Call Rate</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.onCallRate)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Hours per week </div>
              <div className="info-item-data col-7">
                {placement.job?.hoursPerWeek}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      {placement?.approvalStatus ===
        EApplicationApprovalStatus.CLIENT_CONFIRMED && (
        <div className="action-container container">
          <div className="left-container"></div>
          <div className="right-container">
            <Button type="default" onClick={onClickReject}>
              Reject
            </Button>
            <Button type="primary" onClick={onClickApprove}>
              Confirm
            </Button>
          </div>
        </div>
      )}
    </OfferContainer>
  );
};

export default OfferCandidatePage;
