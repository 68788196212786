import React, { useState } from 'react';
import { TitleCard, Button, Textarea } from '../../ui';
import { Candidate } from '../../../backend/careo-api';
import { CreateNewNoteCandidateFormContainer } from './create-new-note-candidate-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';

type AddNewNoteCandidateFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidate: Candidate;
};

export const AddNewNoteCandidateForm = ({
  onCancel,
  onSuccess,
  candidate,
}: AddNewNoteCandidateFormProps) => {
  const [newNote, setNewNote] = useState('');

  const onClickSubmit = () => {
    AxiosInstance.candidates
      .candidatesControllerCreateNoteCandidate(candidate._id, {
        text: newNote,
      })
      .then(() => {
        toast.success('Candidate Note updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <CreateNewNoteCandidateFormContainer>
      <TitleCard>Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
      />
      <div className="form-actions">
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button type="primary" onClick={onClickSubmit}>
          Add Note
        </Button>
      </div>
    </CreateNewNoteCandidateFormContainer>
  );
};
