import { CandidatesListPage, CandidateDetailsPage } from '../pages/candidate';
import {
  SharedCandidatesDetailsPage,
  SharedCandidatesListPage,
} from '../pages/shared-candidates';
import { IRoute } from '../types/routes.type';
import HomeCompliancePage from '../pages/home-compliance/home-compliance.page';
import {
  CandidateComplianceDetailsPage,
  ECandidateComplianceTabs,
} from '../pages/home-compliance/candidate-compliance-details.page';
import { ERoute } from '../utils';
import { ECandidateTabs } from '../pages/candidate/candidate-details/candidate-details.page';

export const CandidatesRoutesCRM: IRoute[] = [
  {
    titleKey: `/${ERoute.CANDIDATES}`,
    path: `/${ERoute.CANDIDATES}`,
    element: <CandidatesListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.GENERAL}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.CONTACT}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.AVAILABILITY}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.PAYROLL}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.DOCUMENTS}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.COMPLIANCE}`,
    element: <CandidateDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateTabs.NOTES}`,
    element: <CandidateDetailsPage />,
  },
];

export const SharedCandidatesRoutes: IRoute[] = [
  {
    titleKey: `/${ERoute.SHARED_CANDIDATES}`,
    path: `/${ERoute.SHARED_CANDIDATES}`,
    element: <SharedCandidatesListPage />,
  },
  {
    path: `/${ERoute.SHARED_CANDIDATES}/:id`,
    element: <SharedCandidatesDetailsPage />,
  },
];

export const CandidatesRoutesCompliance: IRoute[] = [
  {
    titleKey: `/${ERoute.CANDIDATES}`,
    path: `/${ERoute.CANDIDATES}`,
    element: <HomeCompliancePage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id`,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateComplianceTabs.GENERAL}`,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateComplianceTabs.DOCUMENTS}`,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateComplianceTabs.COMPLIANCE}`,
    element: <CandidateComplianceDetailsPage />,
  },
  {
    path: `/${ERoute.CANDIDATES}/:id/${ECandidateComplianceTabs.COMPLIANCE}/:type`,
    element: <CandidateComplianceDetailsPage />,
  },
];
