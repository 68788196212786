import { Navigate, RouteObject } from 'react-router-dom';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';
import { AppLayout } from '../components';
import {
  CandidatesRoutesCRM,
  CandidatesRoutesCompliance,
  SharedCandidatesRoutes,
} from './candidates.routes';
import { ClientsRoutes } from './clients.routes';
import { VacanciesRoutes } from './vacancies.routes';
import { PayrollsRoutes } from './payrolls.routes';
import { PlacementsRoutes } from './placements.routes';
import { CalendarsRoutes } from './calendars.routes';
import { EmailsRoutes, MessagesRoutes } from './messages.routes';
import { SettingsRoutes } from './settings.routes';
import { TimesheetsRoutes } from './timesheets.routes';
import { isCRMApp } from '../environment/app.type';
import { AuthRoutes } from './auth.routes';
import NotFound from '../components/not-found/not-found';
import { OffersRoutes } from './offer.routes';
import { DashboardRoutesCRM } from './dashboard.routes';
import { ERoute } from '../utils';

const homePageRoute = isCRMApp
  ? `/${ERoute.DASHBOARD}`
  : `/${ERoute.CANDIDATES}`;

const routesCRM: IRoute[] = [
  ...DashboardRoutesCRM,
  ...CandidatesRoutesCRM,
  ...SharedCandidatesRoutes,
  ...ClientsRoutes,
  ...VacanciesRoutes,
  ...PayrollsRoutes,
  ...PlacementsRoutes,
  ...CalendarsRoutes,
  ...MessagesRoutes,
  ...EmailsRoutes,
  ...SettingsRoutes,
  ...TimesheetsRoutes,
  ...OffersRoutes,
  { path: '/login', element: <Navigate to={homePageRoute} /> },
  { path: '*', element: <NotFound /> },
];

const routesCompliance: IRoute[] = [
  ...CandidatesRoutesCompliance,
  ...SharedCandidatesRoutes,
  ...MessagesRoutes,
  ...EmailsRoutes,
  ...SettingsRoutes,
  { path: '/login', element: <Navigate to={homePageRoute} /> },
  { path: '*', element: <NotFound /> },
];

export const authRoutes: RouteObject[] = [
  ...AuthRoutes,
  ...TimesheetsRoutes,
  ...OffersRoutes,
  { path: '*', element: <Navigate to={PagesUrls.AUTHENTICATION.Login} /> },
];

const routes = isCRMApp ? routesCRM : routesCompliance;

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={homePageRoute} />,
  },
  {
    element: <AppLayout />,
    children: routes.filter((route) => !route.withoutLayout),
  },
  ...routes.filter((route) => route.withoutLayout),
  { path: '*', element: <Navigate to={homePageRoute} /> },
];
