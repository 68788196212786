import { Candidate } from '../backend/careo-api';

export function generateMessageFromTemplate(
  template: string,
  candidate: Candidate,
): string {
  return (
    template?.replace(/\[\[(.+?)\]\]/g, (_, key) => {
      switch (key) {
        case 'title':
          return candidate.title;
        case 'firstName':
          return candidate.firstName;
        case 'lastName':
          return candidate.lastName;
        default:
          return 'UNKNOWN KEY';
      }
    }) ?? ''
  );
}
