import OfferCandidatePage from '../pages/offer/offer-candidate.page';
import OfferClientPage from '../pages/offer/offer-client.page';
import OfferRecruiterPage from '../pages/offer/offer-recruiter.page';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const OffersRoutes: IRoute[] = [
  {
    path: PagesUrls.OFFER.Candidate,
    element: <OfferCandidatePage />,
    withoutLayout: true,
  },
  {
    path: PagesUrls.OFFER.Client,
    element: <OfferClientPage />,
    withoutLayout: true,
  },
  {
    path: PagesUrls.OFFER.Recruiter,
    element: <OfferRecruiterPage />,
    withoutLayout: true,
  },
];
