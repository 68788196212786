import './App.css';
import { useAuth } from '../contexts/auth.context';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { appRoutes, authRoutes } from '../routes';
import { useEffect } from 'react';
import { isCRMApp } from '../environment/app.type';
import { socket } from '../utils';
import { EWebsocketType } from '../backend/careo-api';

function App() {
  const { verifyToken, isUserAuthenticated, isLoading, user } = useAuth();

  useEffect(() => {
    document.title = isCRMApp ? 'CRM | Careo Ai' : 'Compliance | Careo Ai';
    verifyToken();

    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    const connectHandler = () => {
      if (user) {
        socket.emit('register', { userId: user._id });
      }
    };

    if (user) {
      connectHandler();
    }

    socket.on(EWebsocketType.Connect, connectHandler);

    return () => {
      socket.off(EWebsocketType.Connect, connectHandler);
    };
  }, [user, socket]);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <RouterProvider
        router={createBrowserRouter(
          isUserAuthenticated ? appRoutes : authRoutes,
        )}
      />
    </>
  );
}

export default App;
