import styled from 'styled-components';
import { StatisticsCard } from './card/statistics-card';
import { DashboardStatistics } from '../../backend/careo-api';

const WidgetsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  > div {
    min-width: 170px;
    flex: 1;
  }
`;

type Props = {
  statistics: DashboardStatistics['dashboard'];
};
export const TopStatisticsWidgets = ({
  statistics: { CandidatesStats, ComplianceStats, VacanciesStatistics },
}: Props) => {
  return (
    <WidgetsContainer>
      <StatisticsCard
        differencePercentage={0}
        title="New Candidates"
        value={CandidatesStats.newlyCreated}
      />

      <StatisticsCard
        differencePercentage={0}
        title="New Roles"
        value={VacanciesStatistics.newlyCreated}
      />
      <StatisticsCard
        differencePercentage={0}
        title="New Hires"
        value={VacanciesStatistics.hired}
      />

      <StatisticsCard
        differencePercentage={0}
        title="Roles Unfilled"
        value={VacanciesStatistics.Unfilled}
      />
    </WidgetsContainer>
  );
};
