import React, { useEffect, useState } from 'react';
import { FieldText, Button, CardContainer, TitlePage, RadioGroup } from '../ui';
import {
  EFrequency,
  UpdateTimesheetSettingsDTO,
} from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { daysOfWeekOptions } from '../../constants';

export const TimesheetSetting = () => {
  const [timesheetSettings, setTimesheetSettings] = useState<any>({});

  const getTimesheetSettings = async () => {
    await AxiosInstance.timesheets
      .timesheetsControllerGetTimesheetSettings()
      .then((response) => {
        const result = response as unknown as UpdateTimesheetSettingsDTO;
        if (result) {
          setTimesheetSettings({
            ...result,
            time: result.time,
          });
        }
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickSubmit = async () => {
    if (!timesheetSettings?.frequency || !timesheetSettings?.time) {
      toast.error('Please fill the data');
      return;
    }

    await AxiosInstance.timesheets
      .timesheetsControllerUpdateTimesheetSettings({
        ...timesheetSettings,
        time: timesheetSettings.time,
      })
      .then(() => {
        toast.success('Timesheet Settings updated successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    getTimesheetSettings();
  }, []);

  return (
    <CardContainer className="right-container">
      <div className="settings-title-content">
        <TitlePage>Timesheet</TitlePage>
      </div>
      <hr />
      <div className="settings-content">
        <div className="data-table-container">
          <TitlePage>Select your timesheet frequency</TitlePage>
          <br />
          <RadioGroup
            options={[
              {
                label: 'Every Day',
                value: EFrequency.Daily,
                description:
                  'Timesheet for each placement will be generated for export every 24hrs. This will be generated based on approved placements in the system and associated with each candidate.',
              },
              {
                label: 'Weekly',
                value: EFrequency.Weekly,
                description:
                  'Timesheet for each placement will be generated for export every 7 days. This will be generated based on approved timesheets logged in the system and associated with each candidate.',
              },
            ]}
            name={'frequencyTimesheet'}
            value={timesheetSettings?.frequency}
            onChange={(e) =>
              setTimesheetSettings((prev: any) => ({
                ...prev,
                frequency: e,
              }))
            }
          />
          <br />
          <div className="days-container">
            {timesheetSettings.frequency === EFrequency.Weekly && (
              <RadioGroup
                options={daysOfWeekOptions}
                name={'dayTimesheet'}
                onChange={(value) =>
                  setTimesheetSettings((prev: any) => ({
                    ...prev,
                    dayOfTheWeek: Number(value),
                  }))
                }
                value={timesheetSettings?.dayOfTheWeek}
              />
            )}
            <br />
            <FieldText
              placeholder="Enter here ..."
              label="Time (GMT)"
              required
              type="time"
              onChange={(e) =>
                setTimesheetSettings((prev: any) => ({
                  ...prev,
                  time: e.target.value,
                }))
              }
              value={timesheetSettings?.time}
            />
          </div>
          <div className="form-actions">
            <Button type="primary" onClick={onClickSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
