import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const ComplianceStatisticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  overflow-x: auto;
  .report-card {
    flex: 2;
    .report-container {
      flex: 1;
      gap: 16px;
      display: flex;
      flex-direction: column;
      .items-container {
        display: flex;
        .item {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .icon-container {
            background-color: #f8f8f8;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            path {
              fill: ${(props) => props.theme.text.blackColor};
            }
          }
          .data-container {
            display: flex;
            align-items: center;
            gap: 10px;
            .value {
              font-size: 32px;
              font-weight: 700;
            }
            .percentage {
              background-color: #e7f7ef;
              padding: 4px 10px;
              range: 10px;
              font-size: 12px;
              display: flex;
              gap: 5px;
              align-items: center;
              color: #27a376;
              path {
                fill: #27a376;
              }
              &.danger {
                background-color: #ffedec;
                color: #e03137;
                path {
                  fill: #e03137;
                }
              }
            }
          }
          .title {
          }
        }
      }
    }
  }
  .performance-card {
    flex: 1;
    .performance-container {
      flex: 1;
      gap: 20px;
      display: flex;
      flex-direction: column;
      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .left-container {
        }
        .right-container {
          .calendar-container {
            border: 1px solid #e9eaec;
            border-radius: 8px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            gap: 8px;
            path {
              fill: ${(props) => props.theme.text.blackColor};
            }
          }
        }
      }
      .subtitle-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .item {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 10px;
          .text {
            flex: 1;
          }
          .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            &.green-circle {
              background-color: ${(props) => props.theme.text.greenColor};
            }
            &.yellow-circle {
              background-color: ${(props) => props.theme.text.yellowColor};
            }
            &.red-circle {
              background-color: ${(props) => props.theme.text.redColor};
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .report-card {
      .report-container {
        gap: 10px;
        .items-container {
          display: flex;
          .item {
            gap: 10px;
            .icon-container {
              width: 30px;
              height: 30px;
            }
            .data-container {
              gap: 5px;
              .value {
                font-size: 20px;
              }
              .percentage {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
    .performance-card {
      flex: 1;
      .performance-container {
        flex: 1;
        gap: 20px;
        display: flex;
        flex-direction: column;
        .header-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .left-container {
          }
          .right-container {
            .calendar-container {
              border: 1px solid #e9eaec;
              border-radius: 8px;
              padding: 8px 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              gap: 8px;
              path {
                fill: ${(props) => props.theme.text.blackColor};
              }
            }
          }
        }
        .subtitle-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .item {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 10px;
            .text {
              flex: 1;
            }
            .circle {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              &.green-circle {
                background-color: ${(props) => props.theme.text.greenColor};
              }
              &.yellow-circle {
                background-color: ${(props) => props.theme.text.yellowColor};
              }
              &.red-circle {
                background-color: ${(props) => props.theme.text.redColor};
              }
            }
          }
        }
      }
    }
  }
`;
