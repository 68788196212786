import React from 'react';
import { ConfirmModalContainer } from '../../ui/modals/confirm-modal/confirm-modal.style';
import { Button, TitlePage } from '../../ui';
import { CancelIcon } from '../../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDate,
} from '../../../utils';
import {
  Availability,
  EAiJobShift,
  EJobShift,
} from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type RemoveAvailabilityConfirmModalProps = {
  slotsToRemove: Availability[];
  onSuccess: () => void;
  onCancel: () => void;
};

export const RemoveAvailabilityConfirmModal = ({
  slotsToRemove,
  onSuccess = () => {},
  onCancel = () => {},
}: RemoveAvailabilityConfirmModalProps) => {
  const { id } = useParams();

  const isFull =
    slotsToRemove.filter(
      (el) =>
        el?.availabilityTime === EJobShift.Day ||
        el?.availabilityTime === EJobShift.Night,
    )?.length >= 2;

  const isDay =
    slotsToRemove.filter((el) => el?.availabilityTime === EJobShift.Day)
      ?.length === 1;

  const isNight =
    slotsToRemove.filter((el) => el?.availabilityTime === EJobShift.Night)
      ?.length === 1;

  const onClickRemoveAvailability = (shift: EAiJobShift) => {
    Promise.all(
      slotsToRemove
        .filter((el) => {
          if (shift === EAiJobShift.FullDay) {
            return (
              el?.availabilityTime === EJobShift.Day ||
              el?.availabilityTime === EJobShift.Night
            );
          } else {
            return el?.availabilityTime === (shift as unknown as EJobShift);
          }
        })
        .map((el) =>
          AxiosInstance.availability.availabilityControllerDeleteById(el._id),
        ),
    )
      .then(() => {
        toast.success('Availability Removed successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  if (!slotsToRemove.length) {
    return <></>;
  }

  return (
    <ConfirmModalContainer className="availability">
      <div className="confirmation-container">
        <div className="confirmation-header">
          <TitlePage>Remove Availability</TitlePage>
          <CancelIcon onClick={onCancel} />
        </div>
        <div className="confirmation-body">
          Are you sure you want to remove availability for{' '}
          <b>{formatDate(slotsToRemove[0]?.date ?? '')}</b>?
        </div>
        <div className="confirmation-footer">
          <Button
            type="primary"
            onClick={() =>
              isFull && onClickRemoveAvailability(EAiJobShift.FullDay)
            }
            className="full"
            disabled={!isFull}
          >
            Full
          </Button>
          <Button
            type="danger"
            onClick={() =>
              (isFull || isDay) && onClickRemoveAvailability(EAiJobShift.Day)
            }
            className="day"
            disabled={!isFull && !isDay}
          >
            Day
          </Button>
          <Button
            type="danger"
            onClick={() =>
              (isFull || isNight) &&
              onClickRemoveAvailability(EAiJobShift.Night)
            }
            className="night"
            disabled={!isFull && !isNight}
          >
            Night
          </Button>
          <Button type="default" onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmModalContainer>
  );
};
