export const calculateSumBySelectionRoles = (
  specialityData: Record<string, Record<string, Record<string, number>>>,
  status: string,
  level?: string,
  grade?: string,
) => {
  if (!specialityData[status]) {
    return 0;
  }

  if (!level) {
    return Object.keys(specialityData[status]).reduce(
      (levelSum, currentLevel) => {
        const grades = specialityData[status][currentLevel];
        const gradeSum = Object.values(grades).reduce((a, b) => a + b, 0);
        return levelSum + gradeSum;
      },
      0,
    );
  }

  if (level && !grade) {
    const levelData = specialityData[status][level];
    if (!levelData) {
      console.error(`Level "${level}" not found`);
      return 0;
    }
    return Object.values(levelData).reduce((a, b) => a + b, 0);
  }

  if (level && grade) {
    const levelData = specialityData[status][level];
    if (!levelData || levelData[grade] === undefined) {
      return 0;
    }
    return levelData[grade];
  }

  return 0;
};
