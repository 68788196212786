import styled from 'styled-components';

export const PayrollDetailsContainer = styled.div`
  .payroll-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .back-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }
  .payroll-information-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
  }
  .divider {
    height: 1px;
    background: #f1f2f4;
  }
`;
