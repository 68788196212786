import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '../../ui';
import { NewNotificationsListContainer } from './notifications-list.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getTimeAgo,
} from '../../../utils';
import { toast } from 'react-toastify';
import { NewSmallNotificationRedIcon } from '../../../icons';
import { TNotificationUI } from '../../../utils/notifcations.utils';

type NewNotificationsListProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  notifications: TNotificationUI[];
  onClickNotificationItem: (notification: TNotificationUI) => void;
  getNotifications: any;
};

export const NewNotificationsList = ({
  setIsOpen,
  notifications,
  onClickNotificationItem,
  getNotifications,
}: NewNotificationsListProps) => {
  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickClear = async () => {
    await AxiosInstance.notifications
      .notificationsControllerHideNotifications({
        ids: notifications.map((el) => el._id),
      })
      .then(() => {
        getNotifications();
        setIsOpen(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <NewNotificationsListContainer>
      <div className="notification-content-list">
        {notifications.map((el) => {
          return (
            <div
              className="notification-item"
              onClick={() => onClickNotificationItem(el)}
            >
              <div className="notification-item-top">
                <div className="notification-title">{el?.title}</div>
                <div className="notification-time">
                  {getTimeAgo(el.createdDate)}
                </div>
              </div>
              <div className="notification-item-bottom">
                <div className="notification-description">
                  {el?.description}
                </div>
                {el.isNew && <NewSmallNotificationRedIcon />}
              </div>
            </div>
          );
        })}
      </div>
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button onClick={onClickClear} type="primary">
          Clear All
        </Button>
      </div>
    </NewNotificationsListContainer>
  );
};
