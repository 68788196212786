import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  ConfirmModal,
  EModalTypes,
  HeaderPageContainer,
  NewTrustedClientForm,
  SearchInput,
  SideModal,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UploadButton,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';

import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterTrustedClient,
  filterTrustedClients,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortTrustedClients,
  uploadTrustedClientsCsvRequest,
} from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { TrustedClient, EUserRole } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';
import { UpdateTrustedClientForm } from '../../../components/client/update-trusted-client-form.component';

const numberOfItems = 8;

export const TrustedClientsListPage = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [trustedClients, setTrustedClients] = useState<TrustedClient[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trustedClientsList, setTrustedClientsList] = useState<TrustedClient[]>(
    [],
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [recordToDelete, setRecordToDelete] = useState<TrustedClient>();

  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<string>();

  const [filter, setFilter] = useState<TFilterTrustedClient>({
    search: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const uploadTrustedClientsCsv = async (file: File) => {
    await uploadTrustedClientsCsvRequest(file)
      .then(() => {
        getTrustedClients();
        toast.success('Trusts uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getTrustedClients = async () => {
    setIsLoading(true);
    await AxiosInstance.trustedClients
      .trustedClientsControllerFindAll()
      .then((response) => {
        let result = response.data as unknown as TrustedClient[];
        setTrustedClients(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteTrustedClient = async () => {
    await AxiosInstance.trustedClients
      .trustedClientsControllerDeleteById(recordToDelete?._id!)
      .then(() => {
        toast.success('TrustedClient removed successfully');
        getTrustedClients();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(trustedClientsList, currentPage, numberOfItems);
  }, [trustedClientsList, currentPage]);

  useEffect(() => {
    const filteredData = filterTrustedClients(trustedClients, filter);
    const sortedData = sortTrustedClients(filteredData, sort);
    setTrustedClientsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [trustedClients, filter, sort]);

  useEffect(() => {
    getTrustedClients();
  }, []);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Clients',
            url: ERoute.CLIENTS,
          },
          {
            title: 'Trust',
            url: ERoute.TRUSTED_CLIENTS,
          },
        ]}
      />
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Trusts</TitlePage>
            <SubTitlePage>Manage your Trust</SubTitlePage>
          </div>
          <div className="right-container">
            <UploadButton accept=".csv" onUpload={uploadTrustedClientsCsv}>
              Upload
            </UploadButton>
            <Button type="primary" onClick={() => setIsModalCreateOpen(true)}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search trust"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th onClick={() => onSelectSort('name', setSort)}>
                  <div>
                    <label>Name</label>
                    <SortIcon
                      value={sort.key === 'trustedClientName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('description', setSort)}>
                  <div>
                    <label>Description</label>
                    <SortIcon
                      value={sort.key === 'description' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('createdAt', setSort)}>
                  <div>
                    <label>Created At</label>
                    <SortIcon
                      value={sort.key === 'createdAt' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={100} className="text-center">
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((trustedClient) => {
                        const isTrustedClientBelongsToUser =
                          trustedClient?.user?._id === user?._id ||
                          user?.role === EUserRole.Admin;

                        return (
                          <tr key={trustedClient._id}>
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            <td>{trustedClient.name}</td>
                            <td>{trustedClient.description}</td>
                            <td>{formatDate(trustedClient.createdAt ?? '')}</td>
                            <td>
                              <div className="action-item">
                                <div
                                  className={`edit-icon`}
                                  onClick={() =>
                                    setIsModalUpdateOpen(trustedClient._id)
                                  }
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className={`delete-icon`}
                                  onClick={() =>
                                    setRecordToDelete(trustedClient)
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center">
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        {paginatedData.length > 0 && (
          <div className="pagination-container">
            <div className="navigation-container">
              <div
                className={`left-arrow ${currentPage === 1 && 'disabled'}`}
                onClick={() => navigatePagination(currentPage - 1)}
              >
                <LeftArrowIcon />
              </div>
              <div className="pages-list">
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                ).map((el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div
                className={`right-arrow ${
                  currentPage === totalPages && 'disabled'
                }`}
                onClick={() => navigatePagination(currentPage + 1)}
              >
                <RightArrowIcon />
              </div>
            </div>
            <div className="information-container">
              Showing {currentPage} to {totalPages} of{' '}
              {trustedClientsList.length} entries
            </div>
          </div>
        )}
      </CardContainer>

      <SideModal
        isOpen={!!isModalCreateOpen}
        setIsOpen={() => setIsModalCreateOpen(false)}
        title={'New Trust'}
      >
        <NewTrustedClientForm
          onCancel={() => setIsModalCreateOpen(false)}
          onSuccess={() => {
            getTrustedClients();
            setIsModalCreateOpen(false);
          }}
        />
      </SideModal>

      <SideModal
        isOpen={!!isModalUpdateOpen}
        setIsOpen={() => setIsModalUpdateOpen(undefined)}
        title={'Update Trust'}
      >
        <UpdateTrustedClientForm
          onCancel={() => setIsModalUpdateOpen(undefined)}
          onSuccess={() => {
            getTrustedClients();
            setIsModalUpdateOpen(undefined);
          }}
          trustedClientIdToUpdate={isModalUpdateOpen!}
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Trust"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteTrustedClient()}
      >
        Do you want to delete <b>{recordToDelete?.name}</b> ?
      </ConfirmModal>
    </>
  );
};
