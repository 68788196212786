import TimesheetCandidatePage from '../pages/timesheet/timesheet-candidate.page';
import TimesheetClientPage from '../pages/timesheet/timesheet-client.page';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const TimesheetsRoutes: IRoute[] = [
  {
    path: PagesUrls.TIMESHEET.Candidate,
    element: <TimesheetCandidatePage />,
    withoutLayout: true,
  },
  {
    path: PagesUrls.TIMESHEET.Client,
    element: <TimesheetClientPage />,
    withoutLayout: true,
  },
  {
    path: PagesUrls.TIMESHEET.Download,
    element: <TimesheetClientPage isDownload />,
    withoutLayout: true,
  },
];
