import { useMemo, useState } from 'react';
import DonutChart, { DonutChartConfig } from '../charts/donut-chart';
import { Container } from './charts.style';
import { DashboardStatistics } from '../../backend/careo-api';
import { chartsColors } from '../../constants/colors';
import { array } from 'yup';
import { Chevron } from '../../icons';
import { calculateSumBySelectionRoles } from '../../utils/statistics.utils';

type Props = {
  stats: Record<string, Record<string, Record<string, Record<string, number>>>>;
  onSelectedSpeciality?: (speciality: string) => void;
  isLoading?: boolean;
};

export const DashboardSpecialitiesChart = ({
  stats,
  onSelectedSpeciality,
  isLoading,
}: Props) => {
  const [chartConfig, setChartConfig] = useState<DonutChartConfig>({
    labels: [],
    backgroundColors: [],
    borderColors: [],
    size: 250,
    subtitle: 'Specialities',
    title: 'Specialities',
  });

  const data = useMemo(() => {
    if (!stats) return [];
    const specialities = Object.keys(stats);
    const colors: string[] = [];
    const labels: string[] = [];
    const data: number[] = [];

    specialities
      .map((speciality, index) => {
        const specialityTotal = Object.keys(stats[speciality]).reduce(
          (prev, status) =>
            prev + calculateSumBySelectionRoles(stats[speciality], status),
          0,
        );

        colors.push(chartsColors[index]);

        return { speciality, total: specialityTotal };
      })
      .sort((a, b) => {
        return b.total - a.total;
      })
      .map((entry) => {
        labels.push(entry.speciality);
        data.push(entry.total);
      });

    setChartConfig({
      ...chartConfig,
      labels,
      backgroundColors: colors,
      borderColors: colors,
    });

    return data;
  }, [stats]);

  const [labelsCountToDisplay, setLabelsCountToDisplay] = useState(6);
  return (
    <Container>
      <div className="header">
        <span className="title">Speciality</span>
      </div>
      <DonutChart
        config={chartConfig}
        data={data}
        onArcClick={(label, value) => {
          onSelectedSpeciality?.(label);
        }}
        isLoading={isLoading}
      />

      <div className="labels-container">
        {chartConfig.labels.map((label, index) => {
          if (index < labelsCountToDisplay)
            return (
              <div className="label">
                <div>
                  <div
                    style={{
                      backgroundColor: chartConfig.backgroundColors?.[index],
                    }}
                  ></div>
                  <div>{label}</div>
                </div>
                <div>{data[index]}</div>
              </div>
            );
        })}

        {labelsCountToDisplay < chartConfig.labels.length && (
          <div
            className="show-more"
            onClick={() => setLabelsCountToDisplay(labelsCountToDisplay + 5)}
          >
            <label>Show More</label>
            <Chevron direction="bottom" size={25} />
          </div>
        )}
      </div>
    </Container>
  );
};
