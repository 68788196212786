import React, { useEffect, useState } from 'react';
import {
  Button,
  CardContainer,
  FieldText,
  HeaderPageContainer,
  MultipleSelect,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
} from '../../../components';
import { PlusIcon } from '../../../icons';
import { daysTitleList, monthsList } from '../../../constants';
import { getCalendarByMonthAndYear } from '../../../utils';

export const CalendarPage = () => {
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [weeksDay, setWeeksDays] = useState<
    {
      day: number;
      label: string;
      week: number;
      month: number;
      year: number;
    }[][]
  >([]);

  useEffect(() => {
    if (selectedYear && selectedMonth >= 0) {
      setWeeksDays(getCalendarByMonthAndYear(selectedMonth, selectedYear));
    }
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Calendar</TitlePage>
            <SubTitlePage>Manage your Calendar</SubTitlePage>
          </div>
          <div className="right-container">
            <Button type="primary" onClick={() => {}}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container filter-container-calendar">
          <Select
            placeholder="Select date"
            options={monthsList}
            className="month-input"
            value={selectedMonth}
            onChange={(value) => setSelectedMonth(Number(value))}
          />
          <FieldText
            className="month-input"
            type="number"
            placeholder="Select Year"
            min={2000}
            onChange={(e) => setSelectedYear(Number(e.target.value) ?? 2000)}
            value={selectedYear}
          />
          <Select
            placeholder="Select Week"
            options={weeksDay.map((el, index) => ({
              label: `Week ${index + 1}`,
              value: index,
            }))}
            className="month-input"
            value={selectedWeek}
            onChange={(value) => setSelectedWeek(Number(value))}
          />
          <MultipleSelect
            placeholder="All Offices"
            options={[]}
            onChange={(e) => {}}
            disabled
          />
          <MultipleSelect
            placeholder="All Job Titles"
            options={[]}
            onChange={(e) => {}}
            disabled
          />

          <MultipleSelect
            placeholder="All Status"
            options={[]}
            onChange={(e) => {}}
            disabled
          />
        </div>
        <div className="data-table-container">
          <Table className="calendar">
            <thead>
              <tr>
                {daysTitleList.map((el) => (
                  <th key={el.value}>{el.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {(weeksDay[selectedWeek] ?? []).map((el) => (
                  <td
                    key={el.day}
                    className={el.label !== 'currentMonth' ? 'disabled' : ''}
                  >
                    <div>{el.day}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </CardContainer>
    </>
  );
};
