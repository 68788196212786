import React from 'react';
import { Select, TitleCard, FieldText, Button, FormContainer } from '../ui';
import {
  jobLevelWithGrades,
  shiftList,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import {
  Client,
  CreateJobDto,
  EJobStatus,
  EPayOption,
  User,
} from '../../backend/careo-api';
import { AxiosInstance, vacancySchema } from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

export const NewVacancyForm = ({
  onCancel,
  onSuccess,
  usersList,
  clientsList,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  usersList: User[];
  clientsList: Client[];
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreateJobDto>({
    resolver: yupResolver(vacancySchema as any),
  });
  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.jobs
      .jobsControllerCreateJob({
        ...values,
        hoursPerWeek: Number(values.hoursPerWeek),
        payAmount: Number(values.payAmount),
      })
      .then(() => {
        toast.success('Vacancy added successfully');
        onSuccess();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <FormContainer>
      <Select
        placeholder="Select recruiter"
        label="Recruiter"
        required
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
      />
      <TitleCard>Vacancy Details</TitleCard>
      <Select
        placeholder="Select client"
        label="Client"
        required
        options={[
          ...clientsList.map((el) => ({
            label: el.clientName,
            value: el._id,
          })),
        ]}
        register={register('clientId')}
        error={errors.clientId}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            type="date"
            register={register('vacantFrom')}
            error={errors.vacantFrom}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="End Date"
            required
            type="date"
            register={register('vacantTo')}
            error={errors.vacantTo}
          />
        </div>
      </div>
      <Select
        options={shiftList}
        placeholder="Select Shift"
        label="Shift"
        required
        register={register('shift')}
        error={errors.shift}
      />
      <Select
        options={jobLevelWithGrades.map((el) => ({
          label: el.level,
          value: el.level,
        }))}
        placeholder="Select Job Title"
        label="Job Title"
        required
        register={register('level')}
        error={errors.level}
      />
      <Select
        options={[
          ...(jobLevelWithGrades
            .find((el) => el.level === formValues.level)
            ?.grades?.map((el) => ({ label: el, value: el })) ?? []),
        ]}
        placeholder="Select Grade"
        label="Grade"
        required
        register={register('grade')}
        error={errors.grade}
        disabled={!formValues.level}
      />
      <Select
        options={specialtiesList.map((el) => ({ label: el, value: el }))}
        placeholder="Select Specialty"
        label="Specialty"
        required
        register={register('specialty', { required: true })}
        error={errors.specialty}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Job Description"
        required
        register={register('jobDescription', { required: true })}
        error={errors.jobDescription}
      />
      <Select
        options={[
          { label: 'Hired', value: EJobStatus.Hired },
          { label: 'Pending', value: EJobStatus.Pending },
          { label: 'Active', value: EJobStatus.Active },
          { label: 'Closed', value: EJobStatus.Closed },
        ]}
        placeholder="Select Vacancy Status"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
      />
      <TitleCard>Pay</TitleCard>
      <div className="row">
        <div className="col-md-6">
          <Select
            options={[
              { label: 'Time', value: EPayOption.Time },
              { label: 'Fixed', value: EPayOption.Fixed },
            ]}
            placeholder="Select Pay Option"
            label="Option"
            required
            register={register('payOption', { required: true })}
            error={errors.payOption}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Pay amount (per hour)"
            required
            register={register('payAmount', { required: true })}
            error={errors.payAmount}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Hours per week"
            required
            register={register('hoursPerWeek', { required: true })}
            error={errors.hoursPerWeek}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="VAT"
            required
            register={register('vat', { required: true })}
            error={errors.vat}
          />
        </div>
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
