import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const FieldTextContainer = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    span {
      color: red;
    }
  }

  .input-container {
    position: relative;
    input {
      width: 100%;
      padding: 16px 20px;
      border-radius: 10px;
      border: 1px solid #e9eaec;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      &:focus-visible {
        outline: 1.5px solid #c2c3c6;
      }
      border-color: ${(props) => (props.hasError ? 'red' : '')};
    }
    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      path {
        fill: #111827;
      }
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }
  }

  .error-container {
    font-size: 13px;
    color: red;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 5px;
    label {
      font-size: 10px;
    }
    .input-container {
      position: relative;
      input {
        height: 40px;
        padding: 5px 14px;
        font-size: 10px;
      }
      svg {
        top: 10px;
      }
    }

    .error-container {
      font-size: 9px;
    }
  }
`;
