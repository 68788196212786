import styled from 'styled-components';
import { ITheme, breakpoint } from '../../../constants';

interface MainContentContainerProps {
  isSidebarActive: boolean;
  theme: ITheme;
}

const calculateWidth = ({
  isSidebarActive,
  theme,
}: MainContentContainerProps) =>
  `calc(100% - ${isSidebarActive ? theme.sidebar.open : theme.sidebar.close})`;

export const MainContentContainer = styled.div<MainContentContainerProps>`
  position: fixed;
  top: 0;
  left: ${({ isSidebarActive, theme }) =>
    isSidebarActive ? theme.sidebar.open : theme.sidebar.close};
  height: 100vh;
  width: ${calculateWidth};
  transition: 0.5s;
  overflow-y: auto;
  .divider {
    height: ${(props) => props.theme.header.height};
  }
  .content {
    margin: 10px;
    padding: 10px;
    min-height: 90vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    left: 0;
    width: 100%;

    .divider {
      height: ${(props) => props.theme.header.mobileHeight};
    }

    .content {
      margin: 5px;
      padding: 5px;
      gap: 12px;
      > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;
