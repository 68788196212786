import styled from 'styled-components';

export const StatisticsCardContainer = styled.div`
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: white;
  border-radius: 5px;
  .title {
    font-size: 15px;
    font-weight: 500;
  }

  .stats-value {
    font-size: 32px;
    font-weight: 700;
  }

  .percentage {
    font-size: 14px;
    font-weight: 400;

    .text {
      color: #687588;
    }
  }
`;
