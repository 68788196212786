import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const TextareaContainer = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    span {
      color: red;
    }
  }
  textarea {
    width: 100%;
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #e9eaec;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    &:focus-visible {
      outline: 1.5px solid #c2c3c6;
    }
    border-color: ${(props) => (props.hasError ? 'red' : '')};
  }

  .error-container {
    font-size: 13px;
    color: red;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 5px;
    label {
      font-size: 10px;
    }
    textarea {
      width: 100%;
      padding: 10px 12px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 400;
    }

    .error-container {
      font-size: 9px;
    }
  }
`;
