import React from 'react';
import styled from 'styled-components';

export const NewNotificationsListContainer = styled.div`
  .form-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
  .notification-content-list {
    .notification-item {
      padding: 20px 16px;
      border-radius: 10px;
      cursor: pointer;
      .notification-item-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .notification-title {
          font-size: 12px;
          font-weight: 600;
          color: #111827;
        }
        .notification-time {
          font-size: 10px;
          font-weight: 400;
          color: #a0aec0;
        }
      }
      .notification-item-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .notification-description {
          font-weight: 400;
          color: #687588;
          font-size: 10px;
        }
      }

      &:hover {
        background-color: #8080801a;
      }
    }
  }
`;
