import React, { Dispatch, SetStateAction } from 'react';
import { TitleCard, FieldText, Button, Select } from '../../ui';
import { useForm } from 'react-hook-form';
import { CreateUserDto, EUserRole } from '../../../backend/careo-api';
import { NewUserFormContainer } from './new-user-form.style';
import { AddUserSchema, AxiosInstance } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { isCRMApp } from '../../../environment/app.type';

type NewUserFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getUsers: () => void;
};

export const NewUserForm = ({ setIsOpen, getUsers }: NewUserFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserDto>({
    resolver: yupResolver(AddUserSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.users
      .usersControllerCreateUser(values)
      .then(() => {
        toast.success('User added successfully');
        getUsers();
        onClickClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <NewUserFormContainer>
      <Select
        label="Role"
        required
        register={register('role', { required: true })}
        options={[
          {
            value: isCRMApp ? EUserRole.Recruiter : EUserRole.Officer,
            label: isCRMApp ? 'Recruiter' : 'Officer',
          },
          {
            value: EUserRole.Manager,
            label: 'Manager',
          },
        ]}
        placeholder="Select role"
        error={errors?.role}
      />
      <TitleCard>User Details</TitleCard>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
      />

      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Password"
            required
            register={register('password', { required: true })}
            error={errors.password}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Confirm Password"
            required
            register={register('confirmPassword', { required: true })}
            error={errors.confirmPassword}
          />
        </div>
      </div>

      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewUserFormContainer>
  );
};
