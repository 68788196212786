import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const AutocompleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 16px;
  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
  }
  .selected-item {
    background-color: #0caf60;
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .selected-item button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
  }
  .suggestions-container {
    position: relative;
    .suggestions-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      background-color: white;
      z-index: 1000;
      max-height: 150px;
      overflow-y: auto;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
    }
    .suggestions-list li {
      width: 100%;
      padding: 10px;
      cursor: pointer;
    }
    .suggestions-list li:hover {
      background-color: #f0f0f0;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 12px;
    .selected-items {
      margin-bottom: 5px;
    }
    .selected-item {
      padding: 5px 15px;
    }
    .selected-item button {
    }
    .suggestions-container {
      .suggestions-list {
      }
      .suggestions-list li {
        padding: 5px;
      }
      .suggestions-list li:hover {
      }
    }
  }
`;
