import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import { LoginContainer } from './login.style';
import { AppIcon, AppleIcon, GoogleIcon } from '../../icons';
import { Link } from 'react-router-dom';
import { ForgetPasswordDto, SignInDto } from '../../backend/careo-api';
import { useForm } from 'react-hook-form';
import {
  AuthSchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ForgetPasswordSchema,
} from '../../utils';
import { Button, FieldText, TitlePage } from '../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

export const ForgetPasswordPage = () => {
  const {} = useAuth();
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgetPasswordDto>({
    resolver: yupResolver(ForgetPasswordSchema),
  });

  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onClickForgetPassword = async () => {
    const values = getValues();
    await AxiosInstance.emails
      .emailsControllerForgetPassword(values)
      .then((response: any) => {
        toast.success(
          `A password reset link has been sent. Please check your inbox.`,
        );
        setIsSent(true);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <LoginContainer>
      <div className="left-container">
        <div className="image-container"></div>
        <div className="bottom-container">
          <AppIcon />
          <div className="title">
            Let’s empower your <br /> candidates today.
          </div>
          <div className="subtitle">
            We help to complete all your conveyancing needs easily
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="top-container"></div>
        <div className="content-container">
          <TitlePage className="text-center">Reset your account</TitlePage>
          <br />
          <br />
          <br />
          <br />
          <div className="email-container">
            <div className="input-container">
              <FieldText
                type="email"
                label="Email Address"
                register={register('email', { required: true })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit(onClickForgetPassword)();
                }}
                error={errors.email}
                disabled={isSent}
              />
            </div>
          </div>
          {errorMessage && (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              {errorMessage}
            </span>
          )}
          {isSent ? (
            <div className="register-option">Email is sent successfully</div>
          ) : (
            <Button
              type="primary"
              onClick={handleSubmit(onClickForgetPassword)}
            >
              Reset password
            </Button>
          )}
        </div>
        <div className="bottom-container">
          <div className="copyright">© 2024 Careo . All rights reserved.</div>
          <Link to={''}>Terms & Conditions</Link>
          <a href={'https://www.careo.ai/privacy-policy'} target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
    </LoginContainer>
  );
};
