import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file/select-file-list.component';
import { SideModal } from '../../ui';
import { DocumentsListComponent } from '../../document/documents-list.component';

type ProfessionalRegistrationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'registrationCertificate' | 'registrationChecksFiles';

export const ProfessionalRegistration = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: ProfessionalRegistrationProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<{ key: TFilesKey; file: any }[]>([
    { key: 'registrationCertificate', file: {} },
    { key: 'registrationChecksFiles', file: {} },
  ]);
  const { getValues, setValue } = useForm<{
    registrationCertificate: any;
    registrationChecksFiles: any;
  }>();

  const onClickDelete = (key: TFilesKey) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].file = null;
      onUpdateCompliance(
        key,
        prev[itemIndex].file,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // 5 MB file size limit
      if (selectedFile.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(`File size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`);
        return;
      }

      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].file = file;
      onUpdateCompliance(key, prev[itemIndex].file);
      return [...prev];
    });
  };

  const isAlreadyUploaded = false;
  // const isAlreadyUploaded = useMemo(
  //   () =>
  //     !!filesList.find((el) => el.key === 'registrationCertificate')?.file
  //       ?.fileName,
  //   [filesList],
  // );

  const getFiles = (key: TFilesKey) => {
    const file = filesList.find((el) => el.key === key)?.file;

    if (file?.fileName) {
      return [file];
    }

    return [];
  };

  const onUpdateCompliance = (
    key: 'registrationCertificate' | 'registrationChecksFiles',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance?._id) {
      const metadata = compliance?.metadata;
      const registrationCertificate = metadata?.registrationCertificate;
      const registrationChecksFiles = metadata?.registrationChecksFiles;
      setValue('registrationCertificate', registrationCertificate);
      setValue('registrationChecksFiles', registrationChecksFiles);
      setFilesList([
        { key: 'registrationCertificate', file: registrationCertificate },
        { key: 'registrationChecksFiles', file: registrationChecksFiles },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      <TitleCard>Prof Registration Certificate</TitleCard>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Upload file "
              onChange={(e) => handleFileChange(e, 'registrationCertificate')}
              disabled={isAlreadyUploaded}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() =>
              !isAlreadyUploaded && setIsModalOpen('registrationCertificate')
            }
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>

        <DocumentsListComponent
          documents={getFiles('registrationCertificate')}
          onClickDelete={(id) => onClickDelete('registrationCertificate')}
          getDocuments={getDocuments}
          onDocumentRename={(_) => reFetchCompliance?.()}
        />
      </>
      <TitleCard>Prof Registration Checks</TitleCard>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Upload file "
              onChange={(e) => handleFileChange(e, 'registrationChecksFiles')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('registrationChecksFiles')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>

        <DocumentsListComponent
          documents={getFiles('registrationChecksFiles')}
          onClickDelete={(id) => {
            onClickDelete('registrationChecksFiles');
          }}
          getDocuments={getDocuments}
          onDocumentRename={(_) => {
            reFetchCompliance?.();
          }}
        />
      </>
      <TitleCard>Check Professional Registrations</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="checkRegistration"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
      />
      <div className="info-card compliance-status buttons-container validated-by-ai">
        <Button
          type="primary"
          onClick={() => onClickSubmit(compliance._id, getValues(), true)}
        >
          Validate By AI
        </Button>
      </div>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
        />
      </SideModal>
    </>
  );
};
