import React from 'react';
import {
  ComplianceCandidatesList,
  SubTitlePage,
  TitlePage,
} from '../../components';
import { ComplianceStatistics } from '../../components/compliance/compliance-statistics/compliance-statistics.component';
import { useAuth } from '../../contexts/auth.context';

const HomeCompliancePage = () => {
  const user = useAuth();

  return (
    <>
      <div className="welcome-message" style={{ gap: 0 }}>
        <TitlePage>Hi, {user.user?.firstName}</TitlePage>
        <SubTitlePage>This is your HR report so far</SubTitlePage>
      </div>
      <ComplianceStatistics />
      <ComplianceCandidatesList />
    </>
  );
};

export default HomeCompliancePage;
