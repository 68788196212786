import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .alerts-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    > div {
      flex: 1;
      min-width: 300px;
    }
  }
  .donut-charts {
    flex: 1;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  .dashboard-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap-reverse;
    .dashboard-candidate-list-container {
      display: flex;
      flex: 2;
      max-width: 100%;
      padding: 0;
      .candidate-list-card-container {
        height: 100%;
      }
    }
  }
  .dashboard-subtitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  @media (max-width: ${breakpoint.pad}px) {
  }
`;
