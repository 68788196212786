export const chartsColors = [
  '#FF5733',
  '#FF6F61',
  '#87CEEB',
  '#FFF44F',
  '#228B22',
  '#708090',
  '#E6E6FA',
  '#FFDB58',
  '#008080',
  '#FF6347',
  '#FFB6C1',
  '#000080',
  '#32CD32',
  '#9370DB',
  '#FFD700',
  '#4682B4',
  '#FF4500',
  '#A52A2A',
  '#7FFF00',
  '#00CED1',
  '#FF69B4',
  '#6A5ACD',
  '#BDB76B',
  '#B22222',
  '#20B2AA',
  '#8B008B',
  '#F0E68C',
  '#DDA0DD',
  '#F4A460',
  '#556B2F',
  '#C71585',
  '#ADD8E6',
  '#D2691E',
  '#BA55D3',
  '#FA8072',
  '#7CFC00',
  '#DC143C',
  '#8FBC8F',
  '#F5DEB3',
  '#468499',
  '#DAA520',
  '#66CDAA',
  '#FF00FF',
  '#5F9EA0',
  '#4B0082',
  '#FFA500',
  '#DEB887',
  '#C0C0C0',
  '#00FA9A',
  '#CD5C5C',
  '#191970',
  '#7B68EE',
  '#8B4513',
  '#87CEFA',
  '#9932CC',
  '#696969',
  '#98FB98',
  '#DB7093',
  '#468499',
  '#8A2BE2',
  '#40E0D0',
];
