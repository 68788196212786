import React, { Dispatch, SetStateAction } from 'react';
import { SidebarContainer } from './sidebar.style';
import {
  breakpoint,
  footerSidebarItems,
  sidebarItems,
} from '../../../constants';
import { useLocation, Link } from 'react-router-dom'; // Import useLocation from React Router
import { AppIcon } from '../../../icons';

interface SidebarProps {
  isSidebarActive: boolean;
  setIsSidebarActive: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({
  isSidebarActive,
  setIsSidebarActive,
}: SidebarProps) => {
  const location = useLocation();

  return (
    <SidebarContainer isSidebarActive={isSidebarActive} id="sidebar">
      <nav className="sidebar-container">
        <div className="sidebar-header">
          <div className="logo-container">
            <AppIcon />
            <div
              className="toggle-sidebar"
              onClick={() => setIsSidebarActive(false)}
            >
              <i className="bx bx-menu bx-x" id="header-toggle"></i>
            </div>
          </div>
          <div className="sidebar-list-container">
            {sidebarItems
              .filter((el) => !el.isHidden)
              .map((item) => {
                const isActive = location.pathname.includes(item.route);
                return (
                  <Link
                    to={item.route}
                    key={item.key}
                    className={`${isActive ? 'active' : ''} ${
                      item.isDisabled ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      // to hide the sidebar after we click url to change the page
                      if (window.innerWidth <= breakpoint.mobile) {
                        setIsSidebarActive(false);
                      }
                    }}
                  >
                    {item.icon({})}
                    <span>{item.title}</span>
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="sidebar-footer">
          <div className="sidebar-list-container">
            {footerSidebarItems
              .filter((el) => !el.isHidden)
              .map((item) => {
                const isActive = location.pathname === item.route;
                return (
                  <Link
                    to={item.route}
                    key={item.key}
                    className={`${isActive ? 'active' : ''} ${
                      item.isDisabled ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      // to hide the sidebar after we click url to change the page
                      if (window.innerWidth <= breakpoint.mobile) {
                        setIsSidebarActive(false);
                      }
                    }}
                  >
                    {item.icon({})}
                    <span>{item.title}</span>
                  </Link>
                );
              })}
          </div>
        </div>
      </nav>
    </SidebarContainer>
  );
};
