import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const ComplianceChartContainer = styled.div`
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .green-item {
    fill: #27a376;
    &:hover {
      opacity: 0.8;
    }
  }
  .red-item {
    fill: #ee2f2f;
    &:hover {
      opacity: 0.8;
    }
  }
  .yellow-item {
    fill: #ffd023;
    &:hover {
      opacity: 0.8;
    }
  }

  .chart-details {
    position: absolute;
    text-align: center;
    .chart-title {
      font-size: 14px;
    }
    .chart-subtitle {
      font-size: 12px;
      color: #808080;
    }
  }

  .chart-container {
    width: 200px;
  }
`;

const ComplianceChart = ({
  inProgressTasks,
  passedTasks,
  failedTasks,
  expiredTasks,
}: {
  inProgressTasks: number;
  passedTasks: number;
  failedTasks: number;
  expiredTasks: number;
}) => {
  const chartData = {
    labels: [],
    datasets: [
      {
        data: [expiredTasks + failedTasks, inProgressTasks, passedTasks],
        backgroundColor: ['#ee2f2f', '#ffd023', '#27a376'],
        borderColor: ['#ee2f2f', '#ffd023', '#27a376'],
      },
    ],
  };

  return (
    <ComplianceChartContainer>
      <div className="chart-container">
        <Doughnut data={chartData} />
      </div>
      <div className="chart-details">
        <div className="chart-title">
          {expiredTasks + failedTasks + inProgressTasks + passedTasks}
        </div>
        <div className="chart-subtitle">Total tasks</div>
      </div>
    </ComplianceChartContainer>
  );
};

export default ComplianceChart;
