import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill stylesheet

interface TextEditorProps {
  value: string;
  onChange: (content: string) => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({ value, onChange }) => {
  return <ReactQuill value={value} onChange={onChange} />;
};
