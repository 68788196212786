import {
  VacanciesListPage,
  VacancyDetailsPage,
  VacanciesToDoListPage,
} from '../pages/vacancy';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const VacanciesRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.VACANCIES.Index,
    path: PagesUrls.VACANCIES.Index,
    element: <VacanciesListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: PagesUrls.VACANCIES.Details,
    element: <VacancyDetailsPage />,
  },
  {
    path: PagesUrls.VACANCIES.Applications,
    element: <VacanciesToDoListPage />,
  },
];
