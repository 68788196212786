import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  SearchInput,
  SideModal,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UserIcon,
  UpdateSharedCandidatesList,
  MultipleSelect,
} from '../../../components';
import {
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterCandidate,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Candidate, CandidatesList, User } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import BackAction from '../../../components/ui/back-action';
import { getStatus } from '../../../utils/status.utils';
import { useAuth } from '../../../contexts/auth.context';
import { CandidateDetailsContainer } from '../../candidate/candidate-details/candidate-details.style';

const numberOfItems = 8;

export const SharedCandidatesDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const [candidateListDetails, setCandidateListDetails] =
    useState<CandidatesList | null>(null);
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    grades: [],
    levels: [],
    status: [],
    completions: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);

  const [isModalUpdateListOpen, setIsModalUpdateListOpen] =
    useState<CandidatesList | null>(null);

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickUpdate = (item: CandidatesList) => {
    setIsModalUpdateListOpen(item);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CANDIDATES}/${id}`);
  };

  const getSharedListDetails = async () => {
    AxiosInstance.candidatesLists
      .candidatesListsControllerFindOne(id!)
      .then((response: any) => {
        setCandidateListDetails(response as CandidatesList);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerGetAllCandidates()
      .then((response) => {
        const result = response.data as unknown as Candidate[];
        setCandidates(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, numberOfItems);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(
      candidateListDetails?.candidates ?? [],
      filter,
    );
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData);
    setCurrentPage(1);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [candidateListDetails, filter, sort]);

  useEffect(() => {
    getSharedListDetails();
    getCandidates();
    getUsers();
  }, []);

  if (!candidateListDetails) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer>
        <TabFilter
          filters={[
            {
              title: 'Candidates',
              url: ERoute.CANDIDATES,
            },
            {
              title: 'Lists',
              url: ERoute.SHARED_CANDIDATES,
            },
          ]}
        />
        <BackAction />
        <CardContainer>
          <HeaderPageContainer>
            <div className="left-container">
              <TitlePage>{candidateListDetails.name}</TitlePage>
              <SubTitlePage>Manage your List</SubTitlePage>
            </div>
            <div className="right-container">
              <Button
                type="default"
                onClick={() => onClickUpdate(candidateListDetails)}
                disabled={user?._id !== candidateListDetails.user._id}
              >
                <EditIcon /> Edit List
              </Button>
            </div>
          </HeaderPageContainer>
          <div className="filter-container">
            <SearchInput
              placeholder="Search candidate"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
              }
            />
            <MultipleSelect
              placeholder="All Regions"
              options={regionsWithCounties.map((el) => ({
                label: el.region,
                value: el.region,
              }))}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  regions: values ?? [],
                }));
              }}
            />
            <MultipleSelect
              placeholder="All Counties"
              options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
                (el) => ({ label: el, value: el }),
              )}
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({
                  ...prev,
                  counties: values ?? [],
                }));
              }}
              disabled={!filter.regions.length && !filter.counties.length}
            />
            <MultipleSelect
              placeholder="All Levels"
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({ ...prev, levels: values ?? [] }));
              }}
              options={jobLevelWithGrades.map((el) => ({
                label: el.level,
                value: el.level,
              }))}
            />
            <MultipleSelect
              placeholder="All Grades"
              onChange={(e) => {
                const values = e as string[];
                setFilter((prev) => ({ ...prev, grades: values ?? [] }));
              }}
              options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
                label: el,
                value: el,
              }))}
              disabled={!filter.levels.length && !filter.grades.length}
            />
          </div>
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th className="checkbox-table">
                    <input type="checkbox" />
                  </th>
                  <th onClick={() => onSelectSort('firstName', setSort)}>
                    <div>
                      <label>Name </label>
                      <SortIcon
                        value={sort.key === 'firstName' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('designation', setSort)}>
                    <div>
                      <label>Job title</label>
                      <SortIcon
                        value={sort.key === 'designation' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('grade', setSort)}>
                    <div>
                      <label>NHS Pay Grade</label>
                      <SortIcon
                        value={sort.key === 'grade' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('user', setSort)}>
                    <div>
                      <label>Line Manager</label>
                      <SortIcon value={sort.key === 'user' ? sort.value : ''} />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('county', setSort)}>
                    <div>
                      <label>County</label>
                      <SortIcon
                        value={sort.key === 'county' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('region', setSort)}>
                    <div>
                      <label>Region</label>
                      <SortIcon
                        value={sort.key === 'region' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th onClick={() => onSelectSort('status', setSort)}>
                    <div>
                      <label>Status</label>
                      <SortIcon
                        value={sort.key === 'status' ? sort.value : ''}
                      />
                    </div>
                  </th>
                  <th>
                    <div></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((candidate) => {
                  return (
                    <tr key={candidate._id}>
                      <td className="checkbox-table">
                        <input type="checkbox" />
                      </td>
                      <td>
                        <div className="name-item">
                          <UserIcon
                            firstName={candidate.firstName}
                            lastName={candidate.lastName}
                            entity="candidate"
                          />
                          <div>
                            <div>
                              {candidate.firstName} {candidate.lastName}
                            </div>
                            <div className="email">{candidate.email}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="name-item">
                          <div>
                            <div> {candidate?.designation || '-'}</div>
                            <div className="email">
                              {candidate.department || '-'}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{candidate?.grade}</td>
                      <td>
                        {candidate?.recruiter?.firstName}{' '}
                        {candidate?.recruiter?.lastName}
                      </td>
                      <td>{candidate.address?.county || '-'} </td>
                      <td>{candidate.address?.region || '-'} </td>
                      <td>
                        <Badge text={'Active'} type={getStatus('Active')} />
                      </td>
                      <td>
                        <div className="action-item">
                          <div
                            className="view-icon"
                            onClick={() => onClickView(candidate._id)}
                          >
                            <EyeIcon />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="pagination-container">
            <div className="navigation-container">
              <div
                className={`left-arrow ${currentPage === 1 && 'disabled'}`}
                onClick={() => navigatePagination(currentPage - 1)}
              >
                <LeftArrowIcon />
              </div>
              <div className="pages-list">
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                ).map((el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div
                className={`right-arrow ${
                  currentPage === totalPages && 'disabled'
                }`}
                onClick={() => navigatePagination(currentPage + 1)}
              >
                <RightArrowIcon />
              </div>
            </div>
            <div className="information-container">
              Showing {currentPage} to {totalPages} of {candidatesList.length}{' '}
              entries
            </div>
          </div>
        </CardContainer>

        <SideModal
          isOpen={!!isModalUpdateListOpen}
          setIsOpen={() => setIsModalUpdateListOpen(null)}
          title={'Update List'}
        >
          <UpdateSharedCandidatesList
            onCancel={() => setIsModalUpdateListOpen(null)}
            onSuccess={() => {
              getSharedListDetails();
              setIsModalUpdateListOpen(null);
            }}
            candidates={candidates}
            users={usersList}
            selectedCandidateList={isModalUpdateListOpen!}
          />
        </SideModal>
      </CandidateDetailsContainer>
    </>
  );
};
