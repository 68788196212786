import React, { useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, CardContainer } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  CandidatesList,
  CreateCandidatesListDto,
  User,
} from '../../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  candidateListSchema,
} from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '../../ui/inputs/autocomplete/autocomplete.component';
import FilterCandidates from '../new-shared-candidates-form/filter-candidates.component';
import { useParams } from 'react-router-dom';
import { handleFormsServerErrors } from '../../../utils';

const numberOfItems = 5;

type UpdateSharedCandidatesListProps = {
  candidates: Candidate[];
  users: User[];
  onCancel: () => void;
  onSuccess: () => void;
  selectedCandidateList: CandidatesList;
};

export const UpdateSharedCandidatesList = ({
  candidates,
  users,
  onCancel,
  onSuccess,
  selectedCandidateList,
}: UpdateSharedCandidatesListProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<CreateCandidatesListDto>({
    resolver: yupResolver(candidateListSchema as any),
  });

  const [selectedNewCandidates, setSelectedNewCandidates] = useState<string[]>(
    [],
  );
  const [selectedViewers, setSelectedViewers] = useState<
    { title: string; value: string }[]
  >([]);

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.candidatesLists
      .candidatesListsControllerUpdate(selectedCandidateList._id, {
        name: values.name,
        candidates: selectedNewCandidates,
        sharedUsers: selectedViewers.map((el) => el.value),
      })
      .then(() => {
        toast.success('Candidate List updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      name: selectedCandidateList.name,
    });
    setSelectedNewCandidates(
      selectedCandidateList.candidates.map((el) => el._id),
    );
    setSelectedViewers(
      selectedCandidateList.sharedUsers.map((el) => ({
        title: el.firstName + ' ' + el.lastName,
        value: el._id,
      })),
    );
  }, []);

  return (
    <>
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name', { required: true })}
        error={errors.name}
      />
      <CardContainer className="p-0 rounded-0">
        <TitleCard>Candidates ({selectedNewCandidates.length})</TitleCard>
        <FilterCandidates
          candidates={candidates}
          selectedCandidatesIds={selectedNewCandidates}
          setSelectedCandidatesIds={setSelectedNewCandidates}
        />
        <TitleCard>Viewers ({selectedViewers.length})</TitleCard>
        <Autocomplete
          suggestions={users.map((el) => ({
            title: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          values={selectedViewers}
          onChange={(e) => setSelectedViewers(e)}
        />
      </CardContainer>

      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </>
  );
};
