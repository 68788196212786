import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  &.page-summary-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    > div {
      display: flex;
      align-items: center;
    }
    .module-name {
      display: flex;
      gap: 12px;
      align-items: center;
      .module-info {
        .name {
          font-size: 18px;
          font-weight: 500;
        }
        .availability {
          font-size: 14px;
          font-weight: 400;
          color: #a0aec0;
        }
      }
    }
    .module-contact {
      display: flex;
      gap: 12px;
      font-size: 16px;
      svg {
        path {
          fill: #a0aec0;
        }
      }
    }
    .actions-container {
      display: flex;
      gap: 12px;
    }
    .edit-container {
      background-color: #2f78ee;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
      svg {
        path {
          fill: white;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: gray;
        border: none;
        background-color: #e9ecef;
        svg > path {
          fill: gray;
        }
      }
    }
  }

  .filter-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    select {
      max-width: 170px;
      width: 170px;
    }
    .search-input {
      min-width: 200px;
    }

    .mui-input {
      div {
        input {
          height: unset;
          outline: none;
          border: none;
          width: 120px;
        }
      }
    }

    select,
    input {
      /* height: 54px; */
      /* outline: none;
      border: 1px solid #e9eaec;
      border-radius: 10px; */
    }
  }

  .select-checkbox-action-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .number-selected-items {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    .checkbox-action-buttons-container {
      display: flex;
    }
  }

  .data-table-container {
    overflow-x: auto;
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    .navigation-container {
      display: flex;
      gap: 24px;
      .right-arrow,
      .left-arrow {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f1f2f4;
        cursor: pointer;
        svg {
          path {
            fill: #111827;
          }
        }
        &.disabled {
          cursor: no-drop;
          svg {
            path {
              fill: #a0aec0;
            }
          }
        }
      }

      .pages-list {
        display: flex;
        align-items: center;
        .page-number {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          cursor: pointer;
          &:hover,
          &.active {
            background-color: #f8f8f8;
          }
        }
      }
    }
    .information-container {
      color: #687588;
      font-size: 12px;
    }
  }

  .candidates-names {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tab-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .tab-items-list {
      display: flex;
      flex-wrap: wrap;
      .tab-item {
        padding: 10px 20px;
      }
    }
    .tab-content-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .info-card {
        border-radius: 16px;
        border: 1px solid #f1f2f4;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .availability-actions-container {
          display: flex;
          align-items: center;
          gap: 20px;
          input {
            max-width: 100%;
          }
          svg {
            cursor: pointer;
          }
          input,
          select {
            min-width: 200px;
          }
        }
        .info-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .info-card-title {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
        hr {
          background: #f1f2f4;
          opacity: 1;
        }
        .info-card-content {
          gap: 16px;
          .info-item-title {
            color: #687588;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
          .info-item-data {
            color: #111827;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 18px;
    gap: 18px;

    &.page-summary-container {
      gap: 5px;
      .module-name {
        gap: 5px;
        .module-info {
          .name {
            font-size: 14px;
          }
          .availability {
            font-size: 10px;
          }
        }
      }
      .module-contact {
        gap: 5px;
        font-size: 12px;
      }
      .actions-container {
        gap: 5px;
      }
    }

    .filter-container {
      gap: 10px;
    }

    .select-checkbox-action-container {
      gap: 5px;
      .number-selected-items {
        font-size: 8px;
      }
    }

    .pagination-container {
      font-size: 10px;
      flex-direction: column;
      gap: 5px;
      .navigation-container {
        gap: 5px;
        .right-arrow,
        .left-arrow {
          width: 20px;
          height: 20px;
        }
        .pages-list {
          .page-number {
            width: 20px;
            height: 20px;
          }
        }
      }
      .information-container {
        font-size: 8px;
      }
    }

    .tab-container {
      gap: 15px;
      .tab-items-list {
        .tab-item {
          padding: 5px 10px;
        }
      }
      .tab-content-container {
        gap: 15px;
        .info-card {
          padding: 16px;
          gap: 15px;
          font-size: 14px;
          .availability-actions-container {
            gap: 14px;
            display: flex;
            flex-wrap: wrap;
            input,
            select {
              min-width: 100%;
            }
          }
          hr {
            margin: 0;
          }

          .info-card-title {
            font-size: 14px;
          }
          .info-card-content {
            gap: 10px;
            .info-item-title {
              font-size: 10px;
            }
            .info-item-data {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;
