import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const HeaderPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  .left-container {
  }
  .right-container {
    display: flex;
    gap: 10px;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
