import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  ConfirmModal,
  HeaderPageContainer,
  MultipleSelect,
  NewVacancyForm,
  SearchInput,
  Select,
  SideModal,
  SubTitlePage,
  Table,
  TitlePage,
  UpdateVacancyForm,
  UploadButton,
} from '../../../components';
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  TUIType,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterVacancy,
  filterVacancies,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortVacancies,
  uploadVacanciesCsvRequest,
} from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Client, EJobStatus, Job, User } from '../../../backend/careo-api';
import { toast } from 'react-toastify';

const numberOfItems = 8;

const vacancyStatus: { [key in EJobStatus]: TUIType } = {
  [EJobStatus.Hired]: 'success',
  [EJobStatus.Pending]: 'warning',
  [EJobStatus.Active]: 'primary',
  [EJobStatus.Closed]: 'danger',
};

export const VacanciesListPage = () => {
  const navigate = useNavigate();

  const [vacancies, setVacancies] = useState<Job[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState<TFilterVacancy>({
    search: '',
    regions: [],
    grades: [],
    levels: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });
  const [recordToDelete, setRecordToDelete] = useState<Job>();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickEdit = (vacancy: Job) => {
    setIsUpdateModalOpen(vacancy);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.VACANCIES}/${id}`);
  };

  const onClickAvailability = (id: string) => {
    navigate(`/${ERoute.VACANCIES}/${id}/applications`);
  };

  const uploadVacanciesCsv = async (file: File) => {
    await uploadVacanciesCsvRequest(file)
      .then(() => {
        getClients();
        toast.success('Vacancies uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getVacancies = async () => {
    setIsLoading(true);
    await AxiosInstance.jobs
      .jobsControllerGetAllJobs()
      .then((response) => {
        setVacancies(response.data as unknown as Job[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteVacancy = async () => {
    AxiosInstance.jobs
      .jobsControllerDeleteJob(recordToDelete?._id!)
      .then(() => {
        toast.success('Vacancy Removed successfully');
        setRecordToDelete(undefined);
        getVacancies();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerGetAllClients()
      .then((response) => {
        setClientsList(response.data as unknown as Client[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(vacanciesList, currentPage, numberOfItems);
  }, [vacanciesList, currentPage]);

  useEffect(() => {
    const filteredData = filterVacancies(vacancies, filter);
    const sortedData = sortVacancies(filteredData, sort);
    setVacanciesList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [vacancies, filter, sort]);

  useEffect(() => {
    getVacancies();
    getClients();
    getUsers();
  }, []);

  return (
    <>
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Vacancies</TitlePage>
            <SubTitlePage>Manage your Vacancy</SubTitlePage>
          </div>
          <div className="right-container">
            <UploadButton accept=".csv" onUpload={uploadVacanciesCsv}>
              Upload
            </UploadButton>
            <Button type="primary" onClick={() => setIsAddModalOpen(true)}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search vacancy"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
          />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th onClick={() => onSelectSort('specialty', setSort)}>
                  <div>
                    <label>Vacancy</label>
                    <SortIcon
                      value={sort.key === 'specialty' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('client-name', setSort)}>
                  <div>
                    <label>Client</label>
                    <SortIcon
                      value={sort.key === 'client-name' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('client-firstName', setSort)}>
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'client-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('user-firstName', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('status', setSort)}>
                  <div>
                    <label>Job Status</label>
                    <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('level', setSort)}>
                  <div>
                    <label>Job Title</label>
                    <SortIcon value={sort.key === 'level' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('region', setSort)}>
                  <div>
                    <label>Region</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('status', setSort)}>
                  <div>
                    <label>Client Status</label>
                    <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={100} className="text-center">
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((vacancy) => {
                        return (
                          <tr key={vacancy._id}>
                            <td className="checkbox-table">
                              <input type="checkbox" />
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.specialty}</div>
                                  <div className="email">
                                    {vacancy?.shift
                                      ? vacancy?.shift + ' shift'
                                      : '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.client?.clientName}</div>
                                  <div className="email">
                                    {vacancy?.client?.trusted?.name || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {vacancy?.client?.firstName}{' '}
                                    {vacancy?.client?.lastName}
                                  </div>
                                  <div className="email">
                                    {vacancy?.client?.email}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              {vacancy.user ? (
                                `${vacancy.user?.firstName} ${vacancy.user?.lastName}`
                              ) : (
                                <Badge text={'Unassigned'} type={'warning'} />
                              )}
                            </td>
                            <td>
                              <Badge
                                text={vacancy.status ?? EJobStatus.Pending}
                                type={
                                  vacancyStatus[
                                    vacancy.status ?? EJobStatus.Pending
                                  ]
                                }
                              />
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>{vacancy?.level}</div>
                                  <div className="email">{vacancy?.grade}</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {vacancy?.client?.address?.region || '-'}
                                  </div>
                                  <div className="email">
                                    {vacancy?.client?.address?.county || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Badge
                                text={
                                  vacancy?.client?.isProfileComplete
                                    ? 'Active'
                                    : 'Not Active'
                                }
                                type={
                                  vacancy?.client?.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                              />
                            </td>
                            <td>
                              <div className="action-item">
                                <div
                                  className="download-icon"
                                  onClick={() =>
                                    onClickAvailability(vacancy._id)
                                  }
                                >
                                  <CalendarIcon title="Manage vacancy availabilities" />
                                </div>
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(vacancy._id)}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(vacancy)}
                                >
                                  <EditIcon />
                                </div>

                                <div
                                  className="delete-icon"
                                  onClick={() => setRecordToDelete(vacancy)}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center">
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}{' '}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <div className="navigation-container">
            <div
              className={`left-arrow ${currentPage === 1 && 'disabled'}`}
              onClick={() => navigatePagination(currentPage - 1)}
            >
              <LeftArrowIcon />
            </div>
            <div className="pages-list">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ),
              )}
            </div>
            <div
              className={`right-arrow ${
                currentPage === totalPages && 'disabled'
              }`}
              onClick={() => navigatePagination(currentPage + 1)}
            >
              <RightArrowIcon />
            </div>
          </div>
          <div className="information-container">
            Showing {currentPage} to {totalPages} of {vacanciesList.length}{' '}
            entries
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        title={'New Vacancy'}
      >
        <NewVacancyForm
          onCancel={() => setIsAddModalOpen(false)}
          onSuccess={() => {
            getVacancies();
            setIsAddModalOpen(false);
          }}
          clientsList={clientsList}
          usersList={usersList}
        />
      </SideModal>

      <SideModal
        isOpen={!!isUpdateModalOpen}
        setIsOpen={() => setIsUpdateModalOpen(null)}
        title={'Update Vacancy'}
      >
        <UpdateVacancyForm
          clientsList={clientsList}
          usersList={usersList}
          vacancy={isUpdateModalOpen!}
          onCancel={() => setIsUpdateModalOpen(null)}
          onSuccess={() => {
            getVacancies();
            setIsUpdateModalOpen(null);
          }}
        />
      </SideModal>
      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Vacancy"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteVacancy()}
      >
        Do you want to delete <b>this vacancy</b>
      </ConfirmModal>
    </>
  );
};
