import React, { useEffect, useState } from 'react';
import { CardContainer, TitleCard } from '../../ui';
import {
  BriefcaseIcon,
  CalendarIcon,
  MinusIcon,
  PlusIcon,
  TrendingIcon,
  UsersIcon,
} from '../../../icons';
import { ComplianceStatisticsContainer } from './compliance-statistics.style';
import ComplianceChart from './compliance-chart.component';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { GeneralAnalytics } from '../../../backend/careo-api';

export const ComplianceStatistics = () => {
  const [analytics, setAnalytics] = useState<GeneralAnalytics>();

  const getAnalyticsDetails = async () => {
    AxiosInstance.compliances
      .compliancesControllerGetGeneralAnalytics()
      .then((response) => {
        setAnalytics(response as any);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getAnalyticsDetails();
  }, []);

  return (
    <ComplianceStatisticsContainer>
      <CardContainer className="report-card">
        <div className="report-container">
          <div className="items-container">
            <div className="item">
              <div className="icon-container">
                <BriefcaseIcon />
              </div>
              <div className="data-container">
                <div className="value">{analytics?.inProgressTasks.count}</div>
                <div className="percentage">
                  <TrendingIcon />
                  {analytics?.inProgressTasks.percentage}%
                </div>
              </div>
              <div className="title">Compliance tasks pending</div>
            </div>{' '}
            <div className="item">
              <div className="icon-container">
                <PlusIcon />
              </div>
              <div className="data-container">
                <div className="value">{analytics?.passedTasks.count}</div>
                <div className="percentage">
                  <TrendingIcon />
                  {analytics?.passedTasks.percentage}%
                </div>
              </div>
              <div className="title">Compliance tasks passed</div>
            </div>
          </div>
          <div className="items-container">
            <div className="item">
              <div className="icon-container">
                <UsersIcon />
              </div>
              <div className="data-container">
                <div className="value">{analytics?.failedTasks.count}</div>
                <div className="percentage danger">
                  <TrendingIcon />
                  {analytics?.failedTasks.percentage}%
                </div>
              </div>
              <div className="title">Compliance tasks failed</div>
            </div>
            <div className="item">
              <div className="icon-container">
                <MinusIcon />
              </div>
              <div className="data-container">
                <div className="value">{analytics?.expiredTasks.count}</div>
                <div className={`percentage danger`}>
                  <TrendingIcon />
                  {analytics?.expiredTasks.percentage}%
                </div>
              </div>
              <div className="title">Compliance tasks expired</div>
            </div>
          </div>
        </div>
      </CardContainer>
      <CardContainer className="performance-card">
        <div className="performance-container">
          <div className="header-container">
            <div className="left-container">
              <TitleCard>Compliance</TitleCard>
            </div>
            <div className="right-container">
              <div className="calendar-container">
                All Time
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className="graph-chart">
            <ComplianceChart
              inProgressTasks={analytics?.inProgressTasks.count ?? 0}
              passedTasks={analytics?.passedTasks.count ?? 0}
              failedTasks={analytics?.failedTasks.count ?? 0}
              expiredTasks={analytics?.expiredTasks.count ?? 0}
            />
          </div>
          <div className="subtitle-container">
            <div className="item">
              <div className="circle green-circle"></div>
              <div className="text">Passed Tasks</div>
              <div className="number-of">{analytics?.passedTasks.count}</div>
            </div>
            <div className="item">
              <div className="circle yellow-circle"></div>
              <div className="text">Pending</div>
              <div className="number-of">
                {analytics?.inProgressTasks.count}
              </div>
            </div>
            <div className="item">
              <div className="circle red-circle"></div>{' '}
              <div className="text">Needs Attention</div>
              <div className="number-of">
                {(analytics?.failedTasks.count ?? 0) +
                  (analytics?.expiredTasks.count ?? 0)}
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
    </ComplianceStatisticsContainer>
  );
};
