import React, { useEffect, useState } from 'react';
import { SelectContainer } from './select.style';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

type OptionType = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type FieldTextProps = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: FieldError;
  onClick?: () => void;
  register?: UseFormRegisterReturn<any>;
  onChange?: (_id: (string | number)[]) => void;
  options: OptionType[];
  value?: string[] | number[];
};

export const MultipleSelect = ({
  label,
  value,
  onChange,
  required,
  options,
  register,
  className,
  disabled,
  error,
  placeholder,
  onClick,
}: FieldTextProps) => {
  const [selectedValues, setSelectedValues] = useState<OptionType[]>([]);

  useEffect(() => {
    if (!selectedValues.length) {
      if (!value) {
        setSelectedValues([]);
      } else {
        setSelectedValues(() => {
          return value
            .map((el) => options.find((op) => op.value === el)!)
            .filter((el) => el);
        });
      }
    }
  }, [options, value, disabled]);

  return (
    <SelectContainer
      className={`select-container ${className ?? ''}`}
      hasError={!!error}
      onClick={() => onClick?.()}
    >
      {label && (
        <label htmlFor="">
          {label} {required && <span>*</span>}
        </label>
      )}
      <Autocomplete
        disablePortal
        options={options}
        limitTags={2}
        getOptionLabel={(option) => option.label}
        multiple
        onChange={(event, newValue) => {
          const values = newValue.map((el) => el.value);
          register?.onChange?.({
            target: {
              _id: values,
              name: register.name,
            },
          });
          onChange?.(values);
          setSelectedValues(newValue);
        }}
        value={selectedValues}
        disabled={disabled}
        renderOption={(props, option) => {
          const index = selectedValues.findIndex(
            (el) => el.value === option.value,
          );
          const alreadyExists = index > -1;
          const onClick = (event: any) => {
            setSelectedValues((prev) => {
              if (alreadyExists) {
                prev.splice(index, 1);
              } else {
                prev.push(option);
              }
              const values = prev.map((el) => el.value);
              register?.onChange?.({
                target: {
                  _id: values,
                  name: register.name,
                },
              });
              onChange?.(values);
              return [...prev];
            });
          };
          return (
            <li
              {...props}
              aria-selected={alreadyExists}
              onClick={(e) => onClick(e)}
            >
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            className="mui-input"
            {...params}
            placeholder={placeholder}
          />
        )}
      />
      {error && <span className="error-container">{error?.message}</span>}
    </SelectContainer>
  );
};
