import React, { Dispatch, SetStateAction } from 'react';
import { ConfirmModalContainer } from './confirm-modal.style';
import { CancelIcon, RightArrowIcon } from '../../../../icons';
import { Button, TitlePage } from '../../../ui';

type ConfirmModalProps = {
  isOpen: boolean;
  title: string;
  children?: any;
  onPositiveBtnClick?: () => void;
  onNegativeBtnClick?: () => void;
};

export const ConfirmModal = ({
  isOpen,
  title,
  children,
  onPositiveBtnClick = () => {},
  onNegativeBtnClick = () => {},
}: ConfirmModalProps) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <ConfirmModalContainer>
      <div className="confirmation-container">
        <div className="confirmation-header">
          <TitlePage>{title}</TitlePage>
          <CancelIcon onClick={onNegativeBtnClick} />
        </div>
        <div className="confirmation-body">{children}</div>
        <div className="confirmation-footer">
          <Button type="danger" onClick={onPositiveBtnClick}>
            {title}
          </Button>
          <Button type="default" onClick={onNegativeBtnClick}>
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmModalContainer>
  );
};
