import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_API_URL!, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: Infinity, // Keep trying to reconnect indefinitely
  reconnectionDelay: 1000, // Wait 1 second before trying to reconnect
  timeout: 20000, // Timeout after 20 seconds if no connection is established
  transports: ['websocket'], // Force WebSocket and disable polling
  upgrade: false,
});
