import * as yup from 'yup';

const campaignSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  channel: yup.string().required('Channel is required.'),
  runningTime: yup.string().required('Running time is required.'),
  startDate: yup.string().required('Start Date is required.'),
  campaignTemplateId: yup.string().required('Template is required.'),
});

const campaignTemplateSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  message: yup.string().required('Message is required.'),
});

export { campaignSchema, campaignTemplateSchema };
