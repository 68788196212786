import React, { useEffect, useState } from 'react';
import { AddToVacancyFormContainer } from './add-to-vancancy-form.style';
import { Button, FieldText, Select } from '../../ui';
import {
  Candidate,
  CreateApplicationDto,
  DurationDto,
  Job,
} from '../../../backend/careo-api';
import {
  AddCandidateVacancySchema,
  AxiosInstance,
  AxiosInstanceErrorResponse,
} from '../../../utils';
import { useForm } from 'react-hook-form';
import { applicationStatusList } from '../../../constants';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { MinusIcon, PlusIcon } from '../../../icons';
import { handleFormsServerErrors } from '../../../utils';

type AddToVacancyFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidate: Candidate;
  vacancies: Job[];
};

export const AddToVacancyForm = ({
  onCancel,
  onSuccess,
  candidate,
  vacancies,
}: AddToVacancyFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(AddCandidateVacancySchema as any),
  });

  const [durations, setDurations] = useState<DurationDto[]>([
    {} as DurationDto,
  ]);

  const onClickAddDuration = () => {
    setDurations((prev) => [...prev, {} as DurationDto]);
  };

  const onClickDeleteDuration = (index: number) => {
    if (durations.length <= 1) {
      return;
    }
    setDurations(() => {
      const tempDurations = getValues('durations');
      tempDurations.splice(index, 1);
      setValue('durations', tempDurations);
      const durationErrors = errors?.durations ?? [];
      durationErrors.splice?.(index, 1);
      setError('durations', durationErrors as any);
      return [...tempDurations];
    });
  };

  const onClickSubmit = async () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerCreate({
        ...values,
        candidateId: candidate._id,
      })
      .then(() => {
        toast.success('Candidate added to vacancy successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setValue('candidateId', candidate._id);
  }, [candidate]);

  return (
    <AddToVacancyFormContainer>
      <FieldText
        label="Candidate Name"
        required
        value={candidate.firstName + ' ' + candidate.lastName}
        disabled
      />
      <Select
        placeholder="Select Vacancy"
        label="Vacancy"
        options={[
          ...vacancies.map((el) => ({
            label: el.grade + ' / ' + el.specialty,
            value: el._id,
          })),
        ]}
        register={register('jobId', { required: true })}
        error={errors.jobId}
      />
      <Select
        options={applicationStatusList}
        placeholder="Option"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
      />
      {durations.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Start Date"
              required
              type="date"
              register={register(`durations.${index}.availableFrom`)}
              error={errors.durations?.[index]?.availableFrom}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="End Date"
              required
              type="date"
              register={register(`durations.${index}.availableTo`)}
              error={errors.durations?.[index]?.availableTo}
            />
          </div>
          <div className="col-md-2 d-flex align-items-center delete-icon">
            <Button
              type="danger"
              onClick={() => onClickDeleteDuration(index)}
              disabled={durations.length <= 1}
            >
              <MinusIcon />
            </Button>
          </div>
        </div>
      ))}
      <div className="add-duration-container d-flex justify-content-start">
        <Button type="success" onClick={onClickAddDuration}>
          <PlusIcon /> Add New Duration
        </Button>
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </AddToVacancyFormContainer>
  );
};
