import React from 'react';
import styled from 'styled-components';

const ComplianceStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .text {
    flex: 1;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.green-circle {
      background-color: ${(props) => props.theme.text.greenColor};
    }
    &.yellow-circle {
      background-color: ${(props) => props.theme.text.yellowColor};
    }
    &.red-circle {
      background-color: ${(props) => props.theme.text.redColor};
    }
    &.grey-circle {
      background-color: ${(props) => props.theme.text.greyColor};
    }
    &.orange-circle {
      background-color: ${(props) => props.theme.text.orangeColor};
    }
  }
`;

type ComplianceStatusProps = {
  value?: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED';
};

const getStatusValues = (
  value: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED',
) => {
  switch (value) {
    case 'IN_PROGRESS':
      return {
        className: 'yellow-circle',
        label: 'In progress',
      };
    case 'PASSED':
      return {
        className: 'green-circle',
        label: 'Passed',
      };
    case 'FAILED':
      return {
        className: 'red-circle',
        label: 'Failed',
      };
    case 'EXPIRED':
      return {
        className: 'grey-circle',
        label: 'Expired',
      };
    default:
      return {
        className: 'orange-circle',
        label: value,
      };
  }
};

export const ComplianceStatus = ({
  value = 'IN_PROGRESS',
}: ComplianceStatusProps) => {
  const status = getStatusValues(value);

  return (
    <ComplianceStatusContainer>
      <div className={`circle ${status.className}`}></div>
      <div className="text">{status.label}</div>
    </ComplianceStatusContainer>
  );
};
