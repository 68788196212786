import React from 'react';
import styled from 'styled-components';

export const NewPlacementFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .form-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }

  table {
    .checkbox-table {
      input {
        cursor: pointer;
      }
    }
    .compliance-item {
      width: 100%;
    }
  }
`;
