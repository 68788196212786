import React from 'react';
import { Address } from '../../backend/careo-api';

type AddressDetailsProps = {
  address?: Address;
};

export const AddressDetails = ({ address }: AddressDetailsProps) => {
  return (
    <div className="info-card">
      <div className="info-card-title">Address</div>
      <hr />
      <div className="info-card-content row">
        <div className="row">
          <div className="info-item-title col-2">Address</div>
          <div className="info-item-data col-10">{address?.street || '-'}</div>
        </div>
        <div className="row">
          <div className="info-item-title col-2">City</div>
          <div className="info-item-data col-10">{address?.city || '-'}</div>
        </div>
        <div className="row">
          <div className="info-item-title col-2">County</div>
          <div className="info-item-data col-10">{address?.county || '-'}</div>
        </div>
        <div className="row">
          <div className="info-item-title col-2">Region</div>
          <div className="info-item-data col-10">{address?.region || '-'}</div>
        </div>
        <div className="row">
          <div className="info-item-title col-2">Country</div>
          <div className="info-item-data col-10">{address?.country || '-'}</div>
        </div>
        <div className="row">
          <div className="info-item-title col-2">Post Code</div>
          <div className="info-item-data col-10">{address?.zip || '-'}</div>
        </div>
      </div>
    </div>
  );
};
