import { useMemo, useState } from 'react';
import DonutChart, { DonutChartConfig } from '../charts/donut-chart';
import { Container } from './charts.style';
import { DashboardStatistics, ENhsPayGrade } from '../../backend/careo-api';
import { chartsColors } from '../../constants/colors';
import { array, number } from 'yup';
import { capitalizeFirstLetter, jobLevelWithGrades } from '../../constants';
import { calculateSumBySelectionRoles } from '../../utils/statistics.utils';
import { Dropdown } from '../ui';

type Props = {
  stats: Record<string, Record<string, Record<string, Record<string, number>>>>;
  selectedSpeciality?: string;
  isLoading?: boolean;
};

export const DashboardRolesChart = ({
  stats,
  selectedSpeciality,
  isLoading,
}: Props) => {
  const [chartConfig, setChartConfig] = useState<DonutChartConfig>({
    labels: [],
    backgroundColors: [
      '#8C62FF',
      '#2DD4BF',
      '#FE964A',
      '#BA55D3',
      '#468499',
      '#7CFC00',
    ],
    borderColors: [
      '#8C62FF',
      '#2DD4BF',
      '#FE964A',
      '#BA55D3',
      '#468499',
      '#7CFC00',
    ],
    size: 250,
    subtitle: 'Roles',
    title: 'Roles',
  });

  const [selectedLevel, setSelectedLevel] = useState<string>();
  const [selectedGrade, setSelectedGrade] = useState<string>();

  const LevelsList = jobLevelWithGrades.map((el) => ({
    label: el.level,
    value: el.level,
  }));

  const GradesList = useMemo(() => {
    return (
      jobLevelWithGrades
        .find((el) => el.level === selectedLevel)
        ?.grades?.map((el) => ({ label: el, value: el })) ?? []
    );
  }, [selectedLevel]);

  const data = useMemo(() => {
    if (!stats) return [];
    const specialities = selectedSpeciality
      ? [selectedSpeciality]
      : Object.keys(stats);

    const labels: string[] = Object.keys(stats[specialities[0]] ?? []);

    const defaultResult = Object.fromEntries(labels.map((label) => [label, 0]));

    const sumOfObjects = Object.entries(
      selectedSpeciality ? [stats[selectedSpeciality]] : stats,
    ).reduce((prev, next) => {
      Object.keys(next[1]).forEach((label) => {
        prev[label] += calculateSumBySelectionRoles(
          next[1],
          label,
          selectedLevel,
          selectedGrade,
        );
      });
      return prev;
    }, defaultResult);

    const { data, sortedLabels } = Object.entries(sumOfObjects).reduce(
      (prev, next) => {
        prev.sortedLabels.push(next[0]);
        prev.data.push(next[1]);

        return prev;
      },
      { sortedLabels: [] as string[], data: [] as number[] },
    );

    setChartConfig({
      ...chartConfig,
      labels: sortedLabels,
    });

    return data;
  }, [stats, selectedSpeciality, selectedLevel, selectedGrade]);

  return (
    <Container>
      <div className="header">
        <span className="title">
          {!selectedSpeciality ? 'All' : selectedSpeciality} Roles
        </span>

        <div className="selection">
          <div
            style={{
              zIndex: 12,
              position: 'relative',
            }}
          >
            <Dropdown
              placeholder={'Select Job Title'}
              items={LevelsList}
              setSelectedItemValue={setSelectedLevel}
            />
          </div>

          <div
            style={{
              zIndex: 11,
              position: 'relative',
            }}
          >
            <Dropdown
              placeholder={'Select Grade'}
              items={GradesList}
              setSelectedItemValue={setSelectedGrade}
            />
          </div>
        </div>
      </div>
      <DonutChart config={chartConfig} data={data} isLoading={isLoading} />

      <div className="labels-container">
        {chartConfig.labels.map((label, index) => (
          <div className="label">
            <div>
              <div
                style={{
                  backgroundColor: chartConfig.backgroundColors?.[index],
                }}
              ></div>
              <div>{capitalizeFirstLetter(label)}</div>
            </div>
            <div>{data[index]}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};
