import * as yup from 'yup';
import dayjs from 'dayjs';

const AvailabilitySchema = yup.object().shape({
  typeDuration: yup
    .string()
    .required('Type of duration is required')
    .oneOf(
      ['single', 'multiple'],
      'Type of duration must be either "single" or "multiple"',
    ),
  from: yup
    .string()
    .required('From date is required')
    .test(
      'from-min-today',
      `From date must be today or later`,
      function (value) {
        const now = new Date(new Date().toISOString().split('T')[0]);
        const inputDate = new Date(value);
        if (inputDate && inputDate instanceof Date && inputDate >= now) {
          return true;
        }
        return false;
      },
    )
    .test(
      'from-to-valid',
      'From date must be before To date',
      function (value) {
        const toValue = this.parent.to;
        if (toValue && dayjs(value).isAfter(dayjs(toValue))) {
          return false;
        }
        return true;
      },
    ),
  to: yup
    .string()
    .nullable()
    .when('typeDuration', (typeDurationValue, schema) => {
      return typeDurationValue.includes('multiple')
        ? schema.required('To date is required')
        : schema;
    })
    .when('from', (fromValue, schema) => {
      return fromValue
        ? schema.test(
            'to-from-valid',
            'To date must be after From date',
            function (value) {
              const fromValue = this.parent.from;
              if (value && dayjs(value).isBefore(dayjs(fromValue))) {
                return false;
              }
              return true;
            },
          )
        : schema;
    }),
  availabilityTime: yup.string().required('Availability time is required'),
  candidateId: yup.string().required('Candidate ID is required'),
});

export { AvailabilitySchema };
