import React, { useEffect, useState } from 'react';
import { OfferContainer } from './offer.style';
import { AppIcon } from '../../icons';
import { Button, FieldText } from '../../components';
import { AxiosInstance, formatDate } from '../../utils';
import {
  Application,
  EApplicationApprovalStatus,
} from '../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../utils';
import { useAuth } from '../../contexts/auth.context';

const messagesRecord: Record<EApplicationApprovalStatus, string> = {
  [EApplicationApprovalStatus.NOT_ACTIVE]: 'Placement is not active',
  [EApplicationApprovalStatus.PENDING]: 'Placement is pending.',
  [EApplicationApprovalStatus.CLIENT_CONFIRMED]:
    'Placement is confirmed by client.',
  [EApplicationApprovalStatus.CLIENT_REJECTED]:
    'Placement is rejected by client.',
  [EApplicationApprovalStatus.CANDIDATE_CONFIRMED]:
    'Placement is confirmed by candidate. Please review the submitted placement below.',
  [EApplicationApprovalStatus.CANDIDATE_REJECTED]:
    'Placement is rejected by candidate.',
  [EApplicationApprovalStatus.SYSTEM_CANCELLED]:
    'Placement is automatically cancelled',
  [EApplicationApprovalStatus.RECRUITER_APPROVED]:
    'Placement is already approved.',
  [EApplicationApprovalStatus.RECRUITER_DECLINED]:
    'Placement is already declined.',
  [EApplicationApprovalStatus.RECRUITER_CANCELED]:
    'Placement is cancelled by recruiter.',
  [EApplicationApprovalStatus.RECRUITER_STOPPED]:
    'Placement is stopped by recruiter.',
};

const OfferRecruiterPage = () => {
  const navigate = useNavigate();
  const { placementId } = useParams();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [placement, setPlacement] = useState<Application | null>(null);

  const getOfferDetails = async () => {
    try {
      const result =
        (await AxiosInstance.applications.applicationsControllerGetApplicationById(
          placementId!,
        )) as unknown as Application;
      setPlacement(result);
    } catch (error) {
      toast.error('Something went wrong');
    }
    setIsLoading(false);
  };

  const onClickApprove = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerRecruiterApproveByPlacementId(
        placementId!,
      );
      toast.success('Offer approved successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  const onClickReject = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerRecruiterRejectByPlacementId(
        placementId!,
      );
      toast.success('Offer rejected successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      getOfferDetails();
    }
  }, []);

  if (!user) {
    return null;
  }

  if (isLoading) {
    return <>loading ...</>;
  }

  if (!placement) {
    return <>Offer not found</>;
  }

  return (
    <OfferContainer>
      <div className="content container" id="container">
        <div className="logo-container">
          <AppIcon />
        </div>

        <div className="title">
          {messagesRecord[placement.approvalStatus!] ?? ''}
        </div>

        <FieldText
          label="Placement"
          value={
            placement?.job.client.clientName + ' - ' + placement?.job.specialty
          }
          disabled
        />
        <div className="subtitle">Placement Details</div>
        <div className="personal-info-container">
          <div className="personal-name">
            {placement?.candidate?.title} {placement?.candidate?.firstName}{' '}
            {placement?.candidate?.lastName}
          </div>
          <div className="row">
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Specialty</div>
              <div className="info-item-data col-7">
                {placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Department</div>
              <div className="info-item-data col-7">
                {placement?.candidate?.department || '-'}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Week Commencing</div>
              <div className="info-item-data col-7">
                {formatDate(placement?.availableFrom ?? '')}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Team</div>
              <div className="info-item-data col-7">
                {placement?.job.specialty}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Email Address</div>
              <div className="info-item-data col-7">
                {placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Grade</div>
              <div className="info-item-data col-7">
                {placement?.job?.grade}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Start Date</div>
              <div className="info-item-data col-7">
                {formatDate(placement.availableFrom)}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">End Date</div>
              <div className="info-item-data col-7">
                {formatDate(placement.availableTo)}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Pay Amount</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.job.payAmount)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">On Call Rate</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.onCallRate)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Charge Amount</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.fee)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">On Call Charge</div>
              <div className="info-item-data col-7">
                {formatCurrency(placement.onCallFee)} per hour
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Hours per week </div>
              <div className="info-item-data col-7">
                {placement.job?.hoursPerWeek}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      {placement?.approvalStatus ===
        EApplicationApprovalStatus.CANDIDATE_CONFIRMED && (
        <div className="action-container container">
          <div className="left-container"></div>
          <div className="right-container">
            <Button type="default" onClick={onClickReject}>
              Reject
            </Button>
            <Button type="primary" onClick={onClickApprove}>
              Approve
            </Button>
          </div>
        </div>
      )}
    </OfferContainer>
  );
};

export default OfferRecruiterPage;
