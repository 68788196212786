import { CalendarPage } from '../pages/calendar';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const CalendarsRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.CALENDAR.Index,
    path: PagesUrls.CALENDAR.Index,
    element: <CalendarPage />,
    isVisibleOnSidebar: true,
  },
];
