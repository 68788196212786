import styled from 'styled-components';

export const MessagesPageContainer = styled.div`
  flex: 1;
  gap: 16px !important;
  .messages-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    background: white;
    padding: 10px 0;
    border-radius: 10px;
  }
  .messages-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    flex: 1;
    max-height: 76vh;
    height: 76vh;
    gap: 16px;
    background: unset;

    > div {
      border-radius: 10px;
    }

    .messages-list,
    .message-data {
      background-color: white;
    }
  }
  .left-filter {
    display: flex;
    gap: 20px;
    padding: 0px 24px;
    .filter-item {
      padding: 10px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      padding: 10px 5px;
      cursor: pointer;
      &:hover {
        color: #27a376;
        opacity: 0.9;
      }
      &.active {
        color: #27a376;
        border-bottom: 2px solid #27a376;
      }
    }
  }
  .right-filter {
    display: flex;
    gap: 20px;
    padding: 0px 24px;
    flex-wrap: wrap;
    .search-input,
    .select-container {
      width: 160px;
      border-radius: 10px;
      border: 1px solid #ced0d5;
      input {
        background-color: unset;
      }
    }
  }
`;
