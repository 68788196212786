import React, { useEffect, useState } from 'react';
import { Button, CardContainer, SideModal, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { Campaign, CampaignTemplate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { Switch } from '@mui/material';
import { NewCampaignForm, UpdateCampaignForm } from '../campaign';

type CampaignsListProps = {};

export const CampaignsList = ({}: CampaignsListProps) => {
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<Campaign | null>(null);
  const [templateLists, setTemplateLists] = useState<CampaignTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const onClickDeleteCampaign = async (id: string) => {
    await AxiosInstance.campaigns
      .campaignsControllerDelete(id)
      .then((response) => {
        toast.success('Campaign deleted successfully');
        getCampaigns();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickSwitch = async (idCampaign: string, newValues: boolean) => {
    await AxiosInstance.campaigns
      .campaignsControllerUpdate(idCampaign, {
        isActive: newValues,
      })
      .then((response) => {
        toast.success('Campaign status updated successfully');
        getCampaigns();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCampaignTemplates = async () => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        setTemplateLists(response.data as unknown as CampaignTemplate[]);
      })
      .catch(() => {});
  };

  const getCampaigns = async () => {
    setIsLoading(true);
    await AxiosInstance.campaigns
      .campaignsControllerFindAll()
      .then((response) => {
        setCampaignsList(response.data as unknown as Campaign[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getCampaignTemplates();
    getCampaigns();
  }, []);

  return (
    <>
      <CardContainer className="right-container">
        <div className="settings-title-content">
          <TitlePage>Campaigns</TitlePage>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content">
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <label>Campaign Name</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Template Name</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Frequency</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Active</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th className="action-th">
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={100} className="text-center">
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaignsList.length > 0 ? (
                      <>
                        {campaignsList.map((campaign) => (
                          <tr key={campaign._id}>
                            <td>{campaign.name}</td>
                            <td>{campaign.template.name}</td>
                            <td>{campaign.frequency}</td>
                            <td>
                              <Switch
                                onClick={() =>
                                  onClickSwitch(
                                    campaign._id,
                                    !campaign.isActive,
                                  )
                                }
                                checked={campaign.isActive}
                              />
                            </td>
                            <td>
                              <div className="action-item">
                                <div
                                  className="edit-icon"
                                  onClick={() => setEntityToUpdate(campaign)}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() =>
                                    onClickDeleteCampaign(campaign._id)
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={100} className="text-center">
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Campaign'}
      >
        <NewCampaignForm
          setIsOpen={setIsModalOpen}
          getCampaigns={getCampaigns}
          templateLists={templateLists}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Campaign'}
      >
        <UpdateCampaignForm
          campaign={entityToUpdate as Campaign}
          setIsOpen={() => setEntityToUpdate(null)}
          getCampaigns={getCampaigns}
          templateLists={templateLists}
        />
      </SideModal>
    </>
  );
};
