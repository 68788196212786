import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const FieldFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    span {
      color: red;
    }
  }
  input {
    width: 100%;
    padding: 16px 20px;
    border-radius: 10px;
    border: 1px solid #e9eaec;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    &:focus-visible {
      outline: 1.5px solid #c2c3c6;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    gap: 5px;
    label {
      font-size: 10px;
    }
    input {
      width: 100%;
      padding: 8.5px 20px;
      font-size: 10px;
    }
  }
`;
