import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { TitleCard, FieldText, Button, Select } from '../../ui';
import { useForm } from 'react-hook-form';
import { CreateUserDto, EUserRole, User } from '../../../backend/careo-api';
import { UpdateUserFormContainer } from './update-user-form.style';
import { AxiosInstance, UpdateUserSchema } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { isCRMApp } from '../../../environment/app.type';

type UpdateUserFormProps = {
  user: User;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getUsers: () => void;
};

export const UpdateUserForm = ({
  user,
  setIsOpen,
  getUsers,
}: UpdateUserFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<CreateUserDto>({
    resolver: yupResolver(UpdateUserSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.users
      .usersControllerUpdate(user._id, values)
      .then(() => {
        toast.success('User updated successfully');
        getUsers();
        onClickClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    reset(user);
  }, []);

  return (
    <UpdateUserFormContainer>
      <Select
        control={control}
        label="Role"
        required
        register={register('role', { required: true })}
        options={
          getValues('role') === EUserRole.Admin
            ? [
                {
                  value: EUserRole.Admin,
                  label: 'Admin',
                },
              ]
            : [
                {
                  value: isCRMApp ? EUserRole.Recruiter : EUserRole.Officer,
                  label: isCRMApp ? 'Recruiter' : 'Officer',
                },
                {
                  value: EUserRole.Manager,
                  label: 'Manager',
                },
              ]
        }
        placeholder="Select role"
        error={errors?.role}
        disabled={getValues('role') === EUserRole.Admin}
      />
      <TitleCard>User Details</TitleCard>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
      />
      {/* <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Password"
            required
            register={register('password', { required: true })}
            error={errors.password}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Confirm Password"
            required
            register={register('confirmPassword', { required: true })}
            error={errors.confirmPassword}
          />
        </div>
      </div> */}
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateUserFormContainer>
  );
};
