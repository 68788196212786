import React, { useState } from 'react';
import { MainContentContainer } from './content.style';
import { Header } from '../header/header.component';
import { Sidebar } from '../sidebar/sidebar.component';
import { Outlet } from 'react-router-dom';
import { PORTALS } from '../../../backend/careo-api';

export const AppLayout = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  return (
    <>
      {process.env.REACT_APP_NAME === PORTALS.Crm && (
        <Sidebar
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />
      )}

      <MainContentContainer isSidebarActive={isSidebarActive}>
        <Header
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />
        <div className="divider"></div>
        <div className="content">
          <Outlet />
        </div>
      </MainContentContainer>
    </>
  );
};
