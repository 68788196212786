import React, { Dispatch, SetStateAction } from 'react';
import { Button, Table } from '../../ui';
import { SelectFileListContainer } from './select-file-list.style';
import { PlusIcon } from '../../../icons';
import { HostedFile } from '../../../backend/careo-api';

type SelectFileListProps = {
  fileKey: string;
  documents: HostedFile[];
  addNewFile: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SelectFileList = ({
  fileKey,
  documents,
  setIsOpen,
  addNewFile,
}: SelectFileListProps) => {
  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = (file: any) => {
    addNewFile(fileKey, file);
  };

  return (
    <SelectFileListContainer>
      <Table>
        <thead>
          <tr>
            <th>File Name</th>
            <th className="action-th"></th>
          </tr>
        </thead>
        <tbody>
          {documents.map((el, index) => (
            <tr key={el._id}>
              <td>
                {index + 1}. {el.fileName}
              </td>
              <td>
                <div className="action-item">
                  <div
                    className="edit-icon"
                    onClick={() => {
                      onClickSubmit(el);
                    }}
                  >
                    <PlusIcon />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
      </div>
    </SelectFileListContainer>
  );
};
