import React, { useEffect } from 'react';
import { FieldText, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { Application, DurationDto } from '../../../backend/careo-api';
import { UpdateApplicationFormContainer } from './update-application-form.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  UpdateVacancySchema,
} from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../../utils';

export const UpdateApplicationForm = ({
  onCancel,
  onSuccess,
  applicationToUpdate,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  applicationToUpdate: Application;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<DurationDto>({
    resolver: yupResolver(UpdateVacancySchema),
  });

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdateApplicationDuration(
        applicationToUpdate._id,
        {
          ...values,
        },
      )
      .then(() => {
        toast.success('Application Updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    reset({
      ...applicationToUpdate,
      availableFrom: applicationToUpdate.availableFrom?.split('T')?.[0] ?? '',
      availableTo: applicationToUpdate.availableTo?.split('T')?.[0] ?? '',
    });
  }, [applicationToUpdate]);

  return (
    <UpdateApplicationFormContainer>
      <FieldText
        label="Candidate Name"
        required
        value={
          applicationToUpdate?.candidate?.firstName +
          ' ' +
          applicationToUpdate?.candidate?.lastName
        }
        disabled
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('availableFrom')}
        error={errors.availableFrom}
        min={applicationToUpdate.job.vacantFrom.split('T')[0]}
        max={applicationToUpdate.job.vacantTo.split('T')[0]}
      />
      <FieldText
        placeholder="Enter here ..."
        label="End Date"
        required
        type="date"
        register={register('availableTo')}
        error={errors.availableTo}
        min={applicationToUpdate.job.vacantFrom.split('T')[0]}
        max={applicationToUpdate.job.vacantTo.split('T')[0]}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateApplicationFormContainer>
  );
};
