import React from 'react';

type ProgressBarProps = {
  value: number;
};

export const ProgressBar = ({ value }: ProgressBarProps) => {
  const status = value < 30 ? 'danger' : value < 100 ? 'warning' : 'success';

  return (
    <div className="progress">
      <div
        className={`progress-bar bg-${status}`}
        role="progressbar"
        style={{ width: value + '%' }}
      />
    </div>
  );
};
