import React from 'react';
import { FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { CreateTrustedClientDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  trustedClientSchema,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type NewTrustedClientFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewTrustedClientForm = ({
  onCancel,
  onSuccess,
}: NewTrustedClientFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTrustedClientDto>({
    resolver: yupResolver(trustedClientSchema as any),
  });

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.trustedClients
      .trustedClientsControllerCreateTrustedClient(values)
      .then(() => {
        toast.success('Trust added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <FormContainer>
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name')}
        error={errors.name}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Description"
        register={register('description')}
        error={errors.description}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
