import React from 'react';
import styled from 'styled-components';
import { TUIType, getTypeStyles } from '../../constants';

const BadgeContainer = styled.div<{ type: TUIType }>`
  width: 95px;
  min-width: 95px;
  padding: 4px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  ${(props) => getTypeStyles(props.type)}
`;

interface BadgeProps {
  text: string;
  type: TUIType;
}

export const Badge = ({ text, type }: BadgeProps) => {
  return (
    <BadgeContainer className="badge-data" type={type}>
      {text}
    </BadgeContainer>
  );
};
