import * as yup from 'yup';
import { regex } from '../regex.utils';

const PayrollSchema = yup.object().shape({
  payrollProviderName: yup
    .string()
    .required('Payroll provider name is required.'),
  vatNumber: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Vat must be valid number')
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  title: yup.string().required('Title is required.'),
  leadContactFirstName: yup
    .string()
    .required('First name is required.')
    .matches(
      regex.name,
      'First Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  leadContactLastName: yup
    .string()
    .required('Last name is required.')
    .matches(
      regex.name,
      'Last Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  leadContactEmail: yup
    .string()
    .email('Invalid email format.')
    .required('Email is required.'),
  leadContactJobTitle: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(regex.name, 'Job title must contain only alphabetic characters'),
  leadContactMobilePhoneNumber: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    )
    .required('mobile phone number is required.'),
  leadContactWorkPhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  leadContactFax: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(/^\+\d+$/, 'fax should start with + sign and contain only digits')
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  website: yup
    .string()
    .url()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(:\d{1,5})?(\/\S*)?$/,
      'Invalid website URL. Please enter a valid URL',
    ),
  account: yup.string(),
  employmentType: yup.string(),
  standardWorkingHoursPerDay: yup.string(),
  address: yup.object().shape({
    street: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    city: yup
      .string()
      .matches(regex.name, 'City is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    county: yup
      .string()
      .matches(regex.name, 'County is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    region: yup
      .string()
      .matches(regex.name, 'Region is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    country: yup
      .string()
      .matches(regex.name, 'Country is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    zip: yup
      .string()
      .matches(regex.name, 'ZIP code is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
  }),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(regex.name, 'Bank name is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    accountName: yup
      .string()
      .matches(regex.name, 'Account name is not valid')
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    accountNumber: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
    sortNumber: yup
      .string()
      .transform((value, originalValue) =>
        !originalValue ? undefined : value,
      ),
  }),
  userId: yup.string().required('Recruiter  is required'),
});

export default PayrollSchema;
