import React, { useEffect } from 'react';
import { FieldText, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { Compliance, UpdateComplianceDto } from '../../../backend/careo-api';
import { ComplianceDateFormContainer } from './compliance-date-form.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  UpdateComplianceDatesSchema,
  handleFormsServerErrors,
} from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';

type ComplianceDateFormProps = {
  compliance: Compliance;
  onCancel: () => void;
  onSuccess: () => void;
};

export const ComplianceDateForm = ({
  compliance,
  onCancel,
  onSuccess,
}: ComplianceDateFormProps) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<UpdateComplianceDto>({
    resolver: yupResolver(UpdateComplianceDatesSchema as any),
  });

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.compliances
      .compliancesControllerUpdate(
        compliance._id,
        { validate: false },
        {
          ...values,
        },
      )
      .then(() => {
        toast.success('Compliance updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  useEffect(() => {
    setValue('reminderDate', compliance.reminderDate?.split('T')[0] || '');
    setValue('expiryDate', compliance.expiryDate?.split('T')[0] || '');
  }, [compliance, setValue]);

  return (
    <ComplianceDateFormContainer>
      <FieldText
        placeholder="Enter here ..."
        label={'Reminder Date'}
        required
        type="date"
        register={register('reminderDate', { required: true })}
        error={errors.reminderDate}
      />

      <FieldText
        placeholder="Enter here ..."
        label="Expiry Date"
        required
        type="date"
        register={register('expiryDate', { required: true })}
        error={errors.expiryDate}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </ComplianceDateFormContainer>
  );
};
