import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const TitlePage = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.text.blackColor};

  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 18px;
  }
`;

export const SubTitlePage = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.text.greyColor};
  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 10px;
  }
`;

export const TitleCard = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.text.blackColor};
  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 16px;
  }
`;

export const ATag = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #111827;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #27a376;
    opacity: 0.9;
    color: #27a376;
  }
  &.active {
    border-bottom: 2px solid #27a376;
    color: #27a376;
  }
  @media (max-width: ${breakpoint.mobile}px) {
    font-size: 10px;
  }
`;
