export const getVisiblePages = (page: number, total: number) => {
  const visiblePages = [];

  if (total < 7) {
    for (let i = 1; i <= total; i++) {
      visiblePages.push(i);
    }
  } else {
    if (page !== 1) {
      visiblePages.push(1);
    }

    if (page > 2) {
      visiblePages.push(page - 1);
    }

    visiblePages.push(page);

    if (page < total - 1) {
      visiblePages.push(page + 1);
    }

    if (page !== total) {
      visiblePages.push(total);
    }
  }

  return visiblePages;
};

export const getNumberOfPages = (totalItems: number, itemsPerPage: number) => {
  return Math.ceil(totalItems / itemsPerPage);
};

export const getItemsOfPage = <T>(
  items: T[],
  currentPage: number,
  itemsPerPage: number,
): T[] => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return items.slice(startIndex, endIndex);
};
