import styled from 'styled-components';
import DonutChart, { DonutChartConfig } from '../charts/donut-chart';

const Container = styled.div`
  background-color: white;
  min-width: 300px;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  .header {
    .title {
      font-weight: 500;
      font-size: 20px;
    }
  }

  .labels-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .label {
      display: flex;
      justify-content: space-between;
      > div:nth-child(1) {
        color: #687588;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        > div:nth-child(1) {
          width: 14px;
          height: 14px;
          border-radius: 7px;
        }
      }

      > div:nth-child(2) {
        font-weight: 600;
      }
    }
  }
`;

const donutChartConfig: DonutChartConfig = {
  labels: ['Complete', 'In Progress', 'Expired'],
  backgroundColors: ['#27A376', '#2FED36', '#68C9B2'],
  borderColors: ['#27A376', '#2FED36', '#68C9B2'],
  size: 250,
  subtitle: 'Total Cand',
  title: 'Compliance',
};

type Props = {
  data: number[];
  isLoading?: boolean;
};

export const DashboardComplianceChart = ({ data, isLoading }: Props) => {
  return (
    <Container>
      <div className="header">
        <span className="title">Compliance</span>
      </div>
      <DonutChart config={donutChartConfig} data={data} isLoading={isLoading} />

      <div className="labels-container">
        {donutChartConfig.labels.map((label, index) => (
          <div className="label">
            <div>
              <div
                style={{
                  backgroundColor: donutChartConfig.backgroundColors?.[index],
                }}
              ></div>
              <div>{label}</div>
            </div>
            <div>{data[index]}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};
