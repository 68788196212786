import * as yup from 'yup';
import { regex } from '../regex.utils';

const departmentSchema = yup.object().shape({
  titleContact: yup.string().required('Client name is required'),
  firstNameContact: yup
    .string()
    .required('Client site name is required')
    .matches(regex.name, 'First Name is not valid'),
  lastNameContact: yup
    .string()
    .required('Client site name is required')
    .matches(regex.name, 'Last Name is not valid'),

  departmentContact: yup
    .string()
    .required('Client site is required')
    .matches(regex.name, 'Department is not valid'),
  teamContact: yup
    .string()
    .required('Client site is required')
    .matches(regex.name, 'Department is not valid'),
  jobTitleContact: yup
    .string()
    .required('Job title is required')
    .matches(regex.name, 'Job title is not valid'),
  emailContact: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumberContact: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    )
    .required('Phone number is required.'),
  workPhoneNumberContact: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  titleAccount: yup.string().required('Client name is required'),
  firstNameAccount: yup
    .string()
    .required('Client site name is required')
    .matches(regex.name, 'First Name is not valid'),
  lastNameAccount: yup
    .string()
    .required('Client site name is required')
    .matches(regex.name, 'Last Name is not valid'),
  jobTitleAccount: yup
    .string()
    .required('Job title is required')
    .matches(regex.name, 'Job title is not valid'),
  emailAccount: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumberAccount: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    )
    .required('Phone number is required.'),
  workPhoneNumberAccount: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(regex.name, 'Bank name is not valid')
      .required('Bank name is required'),
    accountName: yup
      .string()
      .matches(regex.name, 'Account name is not valid')
      .required('Account name is required'),
    accountNumber: yup.string().required('Account number is required'),
    sortNumber: yup.string().required('Sort code is required'),
  }),
});

export { departmentSchema };
