import { LoginPage } from '../pages/auth/login.page';
import { ForgetPasswordPage } from '../pages/auth/forget-password.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';
import { PagesUrls } from './page-urls';
import { ResetPasswordPage } from '../pages/auth/reset-password.page';

export const AuthRoutes: IRoute[] = [
  {
    titleKey: `/${ERoute.LOGIN}`,
    path: `/${ERoute.LOGIN}`,
    withoutLayout: true,
    element: <LoginPage />,
  },
  {
    titleKey: `/${ERoute.FORGOT_PASSWORD}`,
    path: `/${ERoute.FORGOT_PASSWORD}`,
    withoutLayout: true,
    element: <ForgetPasswordPage />,
  },
  {
    titleKey: `/${ERoute.RESET_PASSWORD}`,
    path: `/${ERoute.RESET_PASSWORD}/:token`,
    withoutLayout: true,
    element: <ResetPasswordPage />,
  },
];
