import { sidebarWidth } from './sidebar';

export const breakpoint = {
  mobile: 576,
  pad: 992,
};

export const theme = {
  header: {
    height: '65px',
    mobileHeight: '50px',
  },
  sidebar: {
    open: sidebarWidth.opened + 'px',
    close: sidebarWidth.closed + 'px',
  },
  text: {
    blackColor: '#111827',
    greenColor: '#27a376',
    greyColor: '#687588',
    yellowColor: '#FFC837',
    redColor: '#ee2f2f',
    orangeColor: '#ff8837',
  },
  firstColor: '#4723d9',
  firstColorLight: '#afa5d9',
  whiteColor: '#fff',
  bodyFont: "'Nunito', sans-serif",
  normalFontSize: '1rem',
  zIndexFixed: 100,
};

export type ITheme = typeof theme;

declare module 'styled-components' {
  interface DefaultTheme extends ITheme {}
}

export type TUIType =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'default';

export function getTypeStyles(type: string) {
  switch (type) {
    case 'success':
      return `
            color: #0CAF60;
            background-color: #E7F7EF;
            svg path {
              fill: #0CAF60;
            }
          `;
    case 'warning':
      return `
            color: #E6BB20;
            background-color: #FFF6D3;
            svg path {
              fill: #E6BB20;
            }
          `;
    case 'danger':
      return `
            color: #FFEDEC;
            background-color: #E03137;
            svg path {
              fill: #FFEDEC;
            }
          `;
    case 'info':
      return `
            color: #8C62FF;
            background-color: #F4F0FF;
            svg path {
              fill: #8C62FF;
            }
          `;
    case 'primary':
      return `
            color: #FFFFFF;
            background-color: #111827;
            svg path {
              fill: #FFFFFF;
            }
          `;
    default:
      return `
            color: #1f1b2a;
            background-color: #fafafa;
            border: 1px solid #1f1b2a;
            svg path {
              fill: #1f1b2a;
            }
          `;
  }
}
