import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UpdateTrustedClientDto } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  trustedClientSchema,
  handleFormsServerErrors,
} from '../../utils';
import { FieldText, FormContainer, Button } from '../ui';

type UpdateTrustedClientFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  trustedClientIdToUpdate: string;
};

export const UpdateTrustedClientForm = ({
  onCancel,
  onSuccess,
  trustedClientIdToUpdate,
}: UpdateTrustedClientFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<UpdateTrustedClientDto>({
    resolver: yupResolver(trustedClientSchema as any),
  });

  const getTrustedClient = async () => {
    await AxiosInstance.trustedClients
      .trustedClientsControllerFindById(trustedClientIdToUpdate)
      .then((response: any) => {
        reset({
          ...response,
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.trustedClients
      .trustedClientsControllerUpdateById(trustedClientIdToUpdate, values)
      .then(() => {
        toast.success('Trust updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getTrustedClient();
  }, []);

  return (
    <FormContainer>
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name')}
        error={errors.name}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Description"
        register={register('description')}
        error={errors.description}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
