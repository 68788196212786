import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  .left-container {
    background-color: #111827;
    display: flex;
    flex-direction: column;
    flex: 1;

    .image-container {
      flex: 1;
      background-image: url('/assets/img/login-image.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .bottom-container {
      border-top: 5px solid;
      border-color: ${(props) => props.theme.text.greenColor};
      padding: 50px;
      color: #ffffff;
      .title {
        font-size: 48px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 18px;
        font-weight: 450;
      }
    }
  }

  .right-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    padding: 50px;
    width: 550px;
    max-width: 100%;

    .top-container {
    }

    .content-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
      flex: 1;

      .title {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #111827;
      }

      .email-container {
        font-size: 14px;
        .input-container {
          position: relative;
          input {
            padding: 16px 20px;
            border: 1px solid #e9eaec;
            border-radius: 10px;
            width: 100%;
          }
        }
      }

      .forgot-remember-container {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #687588;
        .remember-container {
          display: flex;
          align-items: center;
          gap: 5px;

          input {
            width: 20px;
            height: 20px;
          }
        }

        .forgot-password {
          color: #687588;
          font-size: 14px;
        }
      }

      .login-button {
        width: 100%;
        color: white;
        font-size: 16px;
        background-color: #111827;
        padding: 21px 24px;
        border-radius: 10px;
        font-weight: 700;

        &:hover {
          opacity: 0.95;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }

      .login-with-container {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #687588;

        hr {
          flex: 1;
        }

        label {
          font-size: 14px;
          font-weight: 100;
        }
      }

      .options-connection-container {
        display: flex;
        gap: 20px;

        .button {
          flex: 1;
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
          border: 1px solid #e9eaec;
          border-radius: 12px;
          padding: 16px 24px;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }
        }
      }

      .register-option {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #a0aec0;

        a {
          color: #27a376;
        }
      }
    }

    .bottom-container {
      font-size: 14px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      .copyright {
        color: #a0aec0;
      }

      a {
        color: ${(props) => props.theme.text.blackColor};
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    flex-direction: row;

    .left-container {
      display: none;
    }

    .right-container {
      width: 100%;
      padding: 30px 20px;
      .content-container {
        gap: 20px;
        justify-content: center;
        .title {
          font-size: 18px;
        }
        .email-container {
          font-size: 14px;
          .input-container {
            position: relative;
            input {
              padding: 16px 20px;
              border: 1px solid #e9eaec;
              border-radius: 10px;
              width: 100%;
            }
          }
        }

        .forgot-remember-container {
          font-size: 10px;
          .remember-container {
            gap: 5px;
            input {
              width: 15px;
              height: 15px;
            }
          }
          .forgot-password {
            font-size: 10px;
          }
        }

        .login-button {
          font-size: 12px;
          padding: 10px 12px;
        }

        .login-with-container {
          gap: 5px;
          label {
            font-size: 10px;
          }
        }

        .options-connection-container {
          gap: 10px;
          .button {
            gap: 5px;
            padding: 5px 10px;
            font-size: 12px;
          }
        }
        .register-option {
          font-size: 10px;
        }
      }

      .bottom-container {
        font-size: 10px;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;
