import React, { useEffect, useState } from 'react';
import { Button, CardContainer, ConfirmModal, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { CampaignTemplate } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { SideModal } from '../ui';
import {
  NewCampaignTemplateForm,
  UpdateCampaignTemplateForm,
} from '../campaign';

type CampaignTemplatesProps = {};

export const CampaignTemplates = ({}: CampaignTemplatesProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaignTemplatesList, setCampaignTemplates] = useState<
    CampaignTemplate[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<CampaignTemplate | null>(
    null,
  );

  const [documentIdToBeDelete, setDocumentIdToBeDelete] =
    useState<CampaignTemplate>();

  const onClickDeleteCampaign = async (id: string) => {
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerDelete(id)
      .then((response) => {
        toast.success('Campaign Template deleted successfully');
        getCampaignTemplates();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCampaignTemplates = async () => {
    setIsLoading(true);
    await AxiosInstance.campaignTemplates
      .campaignTemplatesControllerFindAll()
      .then((response) => {
        setCampaignTemplates(response.data as unknown as CampaignTemplate[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getCampaignTemplates();
  }, []);

  return (
    <>
      <CardContainer className="right-container">
        <div className="settings-title-content">
          <TitlePage>Campaign Templates</TitlePage>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content">
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <label>Template Name</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Message</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th className="action-th">
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={100} className="text-center">
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaignTemplatesList.length > 0 ? (
                      <>
                        {campaignTemplatesList.map((campaign) => (
                          <tr key={campaign._id}>
                            <td>{campaign.name}</td>
                            <td>{campaign.message}</td>
                            <td>
                              <div className="action-item">
                                <div
                                  className="edit-icon"
                                  onClick={() => setEntityToUpdate(campaign)}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() =>
                                    setDocumentIdToBeDelete(campaign)
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={100} className="text-center">
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Template'}
      >
        <NewCampaignTemplateForm
          setIsOpen={setIsModalOpen}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Template'}
      >
        <UpdateCampaignTemplateForm
          campaignTemplate={entityToUpdate as CampaignTemplate}
          setIsOpen={() => setEntityToUpdate(null)}
          getCampaignTemplates={getCampaignTemplates}
        />
      </SideModal>

      {documentIdToBeDelete && (
        <ConfirmModal
          isOpen={true}
          title="Delete File"
          onNegativeBtnClick={() => setDocumentIdToBeDelete(undefined)}
          onPositiveBtnClick={() => {
            onClickDeleteCampaign(documentIdToBeDelete?._id!);
            setDocumentIdToBeDelete(undefined);
          }}
        >
          Do you want to delete <b>{documentIdToBeDelete?.name}</b>
        </ConfirmModal>
      )}
    </>
  );
};
