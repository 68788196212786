import dayjs from 'dayjs';
import * as yup from 'yup';

const UpdateComplianceDatesSchema = yup.object().shape({
  reminderDate: yup
    .string()
    .required('Reminder Date is required')
    .test(
      'reminderDate-expiryDate-valid',
      'Reminder date must be before expiry date',
      function (value) {
        const toValue = this.parent.expiryDate;
        if (toValue && dayjs(value).isAfter(dayjs(toValue))) {
          return false;
        }
        return true;
      },
    ),
  expiryDate: yup
    .string()
    .required('Expiry date is required')
    .test(
      'expiryDate-reminderDate-valid',
      'Expiry date must be after reminder date',
      function (value) {
        const toValue = this.parent.reminderDate;
        if (toValue && dayjs(value).isBefore(dayjs(toValue))) {
          return false;
        }
        return true;
      },
    ),
});

export { UpdateComplianceDatesSchema };
