import styled from 'styled-components';
import { breakpoint } from '../../../../constants';

export const RadioGroupComponent = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  .options-container {
    gap: 24px;
    display: flex;
    flex-direction: row;
  }
  .option-container {
    width: 100%;
    .option {
      background: #fff;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
      border-radius: 5px;
      cursor: pointer;
      border: 2px solid lightgrey;
      transition: all 0.3s ease;
      border-radius: 10px;
      border: 1px solid #e9eaec;
      position: relative;
      .title-radio {
        margin-right: 20px;
      }
      .description-radio {
        color: #687588;
        font-size: 14px;
      }
      .dot {
        height: 20px;
        width: 20px;
        background: #d9d9d9;
        border-radius: 50%;
        position: absolute;
        right: 20px;
      }
      .dot::before {
        position: absolute;
        content: '';
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        opacity: 0;
        transform: scale(1.5);
        transition: all 0.3s ease;
      }
      .label-container,
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }

    input[type='radio'] {
      display: none;
    }

    input:checked:checked ~ .option {
      border-color: #0caf60;
    }

    input:checked:checked ~ .option .dot {
      background: #0caf60;
    }

    input:checked:checked ~ .option .dot::before {
      opacity: 1;
      transform: scale(1);
    }
  }

  .error-container {
    font-size: 13px;
    color: red;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .options-container {
      gap: 10px;
    }
    .option-container {
      .option {
        padding: 5px 10px;
        .title-radio {
          margin-right: 10px;
        }
        .description-radio {
          font-size: 10px;
        }
        .dot {
          height: 15px;
          width: 15px;
          right: 15px;
        }
        .dot::before {
          top: 3px;
          left: 3px;
          width: 9px;
          height: 9px;
        }
        .label-container,
        span {
          font-size: 10px;
        }
      }
    }

    .error-container {
      font-size: 9px;
    }
  }
`;
