import styled from 'styled-components';
import { LoopIcon } from '../../icons';
import { breakpoint } from '../../constants';
import { FieldText } from './inputs';

const SearchInputContainer = styled.div`
  flex: 1;
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    right: 15px;
    fill: ${(props) => props.theme.text.blackColor};
  }
  @media (max-width: ${breakpoint.mobile}px) {
    svg {
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
    }
  }
`;

type SearchInputProps = {
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const SearchInput = ({
  placeholder = '',
  disabled,
  className,
  onChange,
}: SearchInputProps) => {
  return (
    <SearchInputContainer className={`search-input ${className ?? ''}`}>
      <FieldText
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
      />
      <LoopIcon />
    </SearchInputContainer>
  );
};
