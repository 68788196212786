import { UseFormSetError, UseFormSetFocus } from 'react-hook-form';
import { AxiosInstance, AxiosInstanceErrorResponse } from './axios.utils';
import { mimeTypes } from '../constants';
import { toast } from 'react-toastify';
import { HostedFile, Invoice, Timesheet } from '../backend/careo-api';
import { Buffer } from 'buffer';

export const handleFormsServerErrors = (
  error: AxiosInstanceErrorResponse,
  setError: UseFormSetError<any>,
  setFocus: UseFormSetFocus<any>,
) => {
  if (error.status === 400) {
    const serverErrors = error.message as unknown as Record<string, string>;
    Object.keys(serverErrors).forEach((fieldName) => {
      setError(
        fieldName === 'whatsappId' ? 'phoneNumber' : (fieldName as any),
        {
          type: 'validate',
          message: serverErrors[fieldName],
        },
      );
      setFocus(fieldName as any);
    });
  }
};

export const downloadFile = (file: HostedFile) => {
  AxiosInstance.files
    .filesControllerDownloadFile(file._id, { format: 'blob' })
    .then((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);

      const extension = mimeTypes[response.type] || 'pdf';
      const fileNameWithoutExtension = file.fileName.split('.')[0];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileNameWithoutExtension || 'downloaded_file'}.${extension}`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosInstanceErrorResponse) => {
      toast.error(error.message);
    });
};

export function downloadBase64File(
  base64String: string,
  fileName: string,
  mimeType: string,
) {
  const buffer = convertBase64FileToBuffer(base64String);

  // Create a Blob from the buffer
  const blob = new Blob([buffer], { type: mimeType });
  const url = URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a); // Append to the document
  a.click(); // Trigger the download
  document.body.removeChild(a); // Clean up
  URL.revokeObjectURL(url); // Release the URL object
}

export const convertBase64FileToBuffer = (base64String: string): Buffer => {
  // Gmail API uses URL-safe base64, so replace `-` with `+` and `_` with `/`
  const base64Data = base64String.replace(/-/g, '+').replace(/_/g, '/');

  return Buffer.from(base64Data, 'base64');
};

export const convertBase64FileToBlob = (base64String: string): Buffer => {
  // Gmail API uses URL-safe base64, so replace `-` with `+` and `_` with `/`
  const base64Data = base64String.replace(/-/g, '+').replace(/_/g, '/');

  return Buffer.from(base64Data, 'base64');
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: new Date().getTime(),
  });
  return file;
};

export function base64ToFile(
  base64: string,
  fileName: string,
  contentType = '',
) {
  const blob = convertBase64FileToBlob(base64);
  return new File([blob], fileName, {
    type: contentType,
    lastModified: new Date().getTime(),
  });
}

export const downloadFileByTimesheet = (timesheet: Timesheet) => {
  AxiosInstance.files
    .filesControllerDownloadFileByTimesheetId(timesheet._id, { format: 'blob' })
    .then((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);

      const extension = mimeTypes[response.type] || 'pdf';
      const fileNameWithoutExtension = 'timesheet_' + timesheet._id;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileNameWithoutExtension || 'downloaded_file'}.${extension}`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosInstanceErrorResponse) => {
      toast.error(error.message);
    });
};

export const downloadFileByInvoice = (invoice: Invoice) => {
  AxiosInstance.files
    .filesControllerDownloadFileByInvoiceId(invoice._id, { format: 'blob' })
    .then((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);

      const extension = mimeTypes[response.type] || 'pdf';
      const fileNameWithoutExtension = 'invoice_' + invoice._id;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileNameWithoutExtension || 'downloaded_file'}.${extension}`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosInstanceErrorResponse) => {
      toast.error(error.message);
    });
};
