import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../constants';

type SwitchProps = {
  value: boolean;
  onClick?: any;
  className?: string;
};

const SwitchContainer = styled.svg`
  cursor: pointer;
  * {
    cursor: pointer;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: 30px;
  }
`;

export const Switch = ({
  value,
  onClick = () => {},
  className,
}: SwitchProps) => {
  return (
    <SwitchContainer
      width="44"
      height="27"
      viewBox="0 0 44 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      {value ? (
        <>
          <rect y="0.5" width="44" height="26" rx="13" fill="#27A376" />
          <rect x="20" y="2.5" width="22" height="22" rx="11" fill="white" />
          <path
            d="M26.9167 13.5L29.8334 16.4166L35.6667 10.5833"
            stroke="#27A376"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </>
      ) : (
        <>
          <rect y="0.5" width="44" height="26" rx="13" fill="#CBD5E0" />
          <rect x="2" y="2.5" width="22" height="22" rx="11" fill="white" />
          <path
            d="M8.91675 13.5H17.0834"
            stroke="#CBD5E0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </>
      )}
    </SwitchContainer>
  );
};
