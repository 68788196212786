import { PlacementsListPage, PlacementDetailsPage } from '../pages/placement';

import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';

export const PlacementsRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.PLACEMENTS.Index,
    path: PagesUrls.PLACEMENTS.Index,
    element: <PlacementsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: PagesUrls.PLACEMENTS.Details,
    element: <PlacementDetailsPage />,
  },
];
