import React, { useEffect, useState } from 'react';
import {
  Alert,
  CardContainer,
  DashboardComplianceChart,
  DashboardRolesChart,
  DashboardSpecialitiesChart,
  Dropdown,
  SubTitlePage,
  TitlePage,
} from '../../components';
import { Link } from 'react-router-dom';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { DashboardStatistics, User } from '../../backend/careo-api';
import { CandidatesListPage } from '../candidate';
import * as moment from 'moment-timezone';
import { useAuth } from '../../contexts/auth.context';
import { DashboardContainer } from './dashboard.style';
import { toast } from 'react-toastify';

enum ETimePeriodsList {
  Today = 'Today',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastSixMonths = 'LastSixMonths',
  LastYear = 'LastYear',
  AllTime = 'AllTime',
}

const TimePeriodsList = [
  { value: 'Today', label: 'Today' },
  { value: 'LastWeek', label: 'Last Week' },
  { value: 'LastMonth', label: 'Last Month' },
  { value: 'LastSixMonths', label: 'Last 6 Months' },
  { value: 'LastYear', label: 'Last Year' },
  { value: 'AllTime', label: 'All Time' },
];

export const DashboardPage = () => {
  const { user } = useAuth();

  const [dateRange, setDateRange] = useState<
    | {
        fromDate?: string;
        toDate?: string;
      }
    | undefined
  >();

  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    ETimePeriodsList.Today,
  );
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);

  const [statistics, setStatistics] = useState<DashboardStatistics>({
    dashboard: {
      _id: '',
      CandidatesStats: { newlyCreated: 0 },
      VacanciesStatistics: {
        newlyCreated: 0,
        hired: 0,
        pending: 0,
        active: 0,
        closed: 0,
        Filled: 0,
        Unfilled: 0,
      },
      ComplianceStats: { IN_PROGRESS: 0, PASSED: 0, FAILED: 0, EXPIRED: 0 },
      user: {} as User,
      VacStatusSpecialitiesStats: {},
    },
    liveStats: {
      incompleteProfiles: 0,
      placementsWithIncompleteProfiles: 0,
      clientsWithIncompleteProfiles: 0,
      payrollsWithIncompleteProfiles: 0,
    },
  });

  const complianceStats = statistics.dashboard.ComplianceStats;
  const rolesStats = statistics.dashboard.VacanciesStatistics;
  const vacanciesStatusSpecialitiesStats =
    statistics.dashboard.VacStatusSpecialitiesStats;
  const {
    clientsWithIncompleteProfiles,
    incompleteProfiles,
    payrollsWithIncompleteProfiles,
    placementsWithIncompleteProfiles,
  } = statistics.liveStats;

  const fetchDashboardStatistics = async () => {
    setIsLoading(true);
    await AxiosInstance.statistics
      .statisticsControllerFetchDashboardStatistics(dateRange)
      .then((result) => {
        setStatistics(result as unknown as DashboardStatistics);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (dateRange) fetchDashboardStatistics();
  }, [dateRange]);

  useEffect(() => {
    switch (selectedPeriod) {
      case ETimePeriodsList.Today:
        setDateRange({
          fromDate: undefined,
          toDate: undefined,
        });
        break;
      case ETimePeriodsList.LastWeek:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'week')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastMonth:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastSixMonths:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(6, 'month')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.LastYear:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(1, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      case ETimePeriodsList.AllTime:
        setDateRange({
          fromDate: moment
            .default(new Date())
            .subtract(10, 'year')
            .format('YYYY-MM-DD'),
          toDate: moment.default(new Date()).format('YYYY-MM-DD'),
        });
        break;

      default:
        setDateRange({
          fromDate: undefined,
          toDate: undefined,
        });
        break;
    }
  }, [selectedPeriod]);

  return (
    <DashboardContainer>
      <div className="alerts-container">
        {incompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some candidates that are incomplete.
                <strong>
                  <Link to={'/candidates?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {payrollsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some payrolls that are incomplete.
                <strong>
                  <Link to={'/payrolls?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {clientsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some clients that are incomplete.
                <strong>
                  <Link to={'/clients?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}

        {placementsWithIncompleteProfiles > 0 && (
          <Alert
            type={'danger'}
            message={
              <>
                There are some placements that are incomplete.{' '}
                <strong>
                  <Link to={'/placements?isProfileComplete=false'}>
                    Click here
                  </Link>
                </strong>
              </>
            }
          />
        )}
      </div>
      <div>
        <TitlePage>Hi, {user?.firstName}</TitlePage>
        <div className="dashboard-subtitle">
          <SubTitlePage>This is your HR report so far</SubTitlePage>
          <Dropdown
            items={TimePeriodsList}
            setSelectedItemValue={setSelectedPeriod}
            defaultValue={ETimePeriodsList.Today}
          />
        </div>
      </div>
      <div className="donut-charts">
        <DashboardComplianceChart
          data={[
            complianceStats.PASSED,
            complianceStats.IN_PROGRESS,
            complianceStats.FAILED,
          ]}
          isLoading={isLoading}
        />
        <DashboardSpecialitiesChart
          stats={vacanciesStatusSpecialitiesStats as any}
          onSelectedSpeciality={setSelectedSpeciality}
          isLoading={isLoading}
        />

        <DashboardRolesChart
          stats={vacanciesStatusSpecialitiesStats as any}
          selectedSpeciality={selectedSpeciality}
          isLoading={isLoading}
        />
      </div>
      {/* <TopStatisticsWidgets statistics={statistics.dashboard} /> */}
      <div className="dashboard-content">
        <CardContainer className="dashboard-candidate-list-container">
          <div>
            <CandidatesListPage isDashboard={true} />
          </div>
        </CardContainer>
      </div>
    </DashboardContainer>
  );
};
