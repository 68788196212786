import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const MessageContentContainer = styled.div`
  gap: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .header-conversation {
    padding: 24px;
    border-bottom: 1px solid #f1f2f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .profile-image {
      width: 40px;
      height: 40px;
    }
    .info-messenger {
      cursor: pointer;

      flex: 1;
      .name {
        font-size: 16px;
        font-weight: 500;
        color: #111827;
      }
      .status {
        font-size: 12px;
        font-weight: 400;
        color: #a0aec0;
      }
    }
    .more-options {
      svg {
        path {
          fill: #a0aec0;
        }
      }
    }
  }

  .content-conversation {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    overflow-y: auto;
    .message-item {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      color: #111827;
      word-break: break-word;
      &.sent {
        justify-content: flex-end;
        & > div {
          background-color: #e7f7ef;
          border-radius: 16px 0px 16px 16px;
          padding: 16px 19px;
          max-width: 500px;
          white-space: pre-line;
        }
      }
      &.reply {
        justify-content: flex-start;
        & > div {
          background-color: #f8f8f8;
          border-radius: 0px 16px 16px 16px;
          padding: 16px 19px;
          max-width: 500px;
          white-space: pre-line;
        }
      }
      .file-downloadable {
        display: flex;
        gap: 5px;
        cursor: pointer;
        svg path {
          fill: #111827;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .email-item {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #f1f2f4;
      background-color: #fff;
      padding: 24px;
      .email-title {
        font-size: 14px;
        font-weight: 500;
      }
      .email-attachment-file-container {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        .attachment-file {
          display: flex;
          align-items: center;
          border: 1px solid #525252;
          padding: 3px 7px;
          border-radius: 10px;
          color: #111827;
          font-size: 14px;
          gap: 5px;
          cursor: pointer;
          svg {
            fill: #111827;
          }
          &:hover {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
              rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
          }
        }
      }
      .email-content {
        font-size: 13px;
        margin: 16px 0 30px 0;
        white-space: break-spaces;
      }
      .email-time {
        display: flex;
        align-items: center;
        font-size: 12px;
        gap: 6px;
        svg {
          path {
            fill: #111827;
          }
        }
      }
      &.reply {
        background-color: #f9fafb;
      }
    }
  }

  .input-area {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    border-top: 1px solid #f1f2f4;
    gap: 10px;

    .attach-button {
      cursor: pointer;
      button {
        width: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      }
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: #111827;
        }
      }
    }
    .input-container {
      flex: 1;
      textarea {
        resize: none;
        width: 100%;
        padding: 5px 10px;
        border: 1px solid #f1f2f4;
        font-size: 14px;
        border-radius: 10px;
        outline: none;
      }
    }
    .send-button {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      background-color: #27a376;
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      }
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .footer-email {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .no-conversation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 16px;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .header-conversation {
      padding: 10px;
      gap: 5px;
      .profile-image {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .info-messenger {
        .name {
          font-size: 12px;
        }
        .status {
          font-size: 8px;
        }
      }
    }

    .content-conversation {
      padding: 10px;
      gap: 5px;
      .message-item {
        font-size: 10px;
        &.sent {
          & > div {
            padding: 5px 8px;
            max-width: 300px;
          }
        }
        &.reply {
          & > div {
            padding: 5px 8px;
            max-width: 300px;
          }
        }
      }

      .email-item {
        padding: 10px;
        .email-title {
          font-size: 10px;
        }
        .email-content {
          font-size: 9px;
        }
        .email-time {
          font-size: 8px;
        }
      }
    }

    .input-area {
      padding: 5px 8px;
      gap: 5px;
      .attach-button {
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .input-container {
        flex: 1;
        textarea {
          font-size: 10px;
        }
      }
      .send-button {
        width: 25px;
        height: 25px;
      }
    }

    .emails-container {
    }

    .footer-email {
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }

    .no-conversation {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
